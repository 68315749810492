// extracted by mini-css-extract-plugin
export var abbr = "Markdown-module--abbr--9a2d6";
export var blockquote = "Markdown-module--blockquote--249bf";
export var cdsAssistiveText = "Markdown-module--cds--assistive-text--04f3c";
export var cdsHideFeedback = "Markdown-module--cds--hide-feedback--dff39";
export var cdsLayoutConstraintDensity__defaultCondensed = "Markdown-module--cds--layout-constraint--density__default-condensed--b9554";
export var cdsLayoutConstraintDensity__defaultNormal = "Markdown-module--cds--layout-constraint--density__default-normal--dc0fe";
export var cdsLayoutConstraintDensity__maxCondensed = "Markdown-module--cds--layout-constraint--density__max-condensed--834b3";
export var cdsLayoutConstraintDensity__maxNormal = "Markdown-module--cds--layout-constraint--density__max-normal--21d3e";
export var cdsLayoutConstraintDensity__minCondensed = "Markdown-module--cds--layout-constraint--density__min-condensed--f2a32";
export var cdsLayoutConstraintDensity__minNormal = "Markdown-module--cds--layout-constraint--density__min-normal--5329c";
export var cdsLayoutConstraintSize__default2xl = "Markdown-module--cds--layout-constraint--size__default-2xl--f8b05";
export var cdsLayoutConstraintSize__defaultLg = "Markdown-module--cds--layout-constraint--size__default-lg--c935b";
export var cdsLayoutConstraintSize__defaultMd = "Markdown-module--cds--layout-constraint--size__default-md--990b0";
export var cdsLayoutConstraintSize__defaultSm = "Markdown-module--cds--layout-constraint--size__default-sm--0054e";
export var cdsLayoutConstraintSize__defaultXl = "Markdown-module--cds--layout-constraint--size__default-xl--ad9c4";
export var cdsLayoutConstraintSize__defaultXs = "Markdown-module--cds--layout-constraint--size__default-xs--53ce7";
export var cdsLayoutConstraintSize__max2xl = "Markdown-module--cds--layout-constraint--size__max-2xl--a1e64";
export var cdsLayoutConstraintSize__maxLg = "Markdown-module--cds--layout-constraint--size__max-lg--ac0c7";
export var cdsLayoutConstraintSize__maxMd = "Markdown-module--cds--layout-constraint--size__max-md--b129b";
export var cdsLayoutConstraintSize__maxSm = "Markdown-module--cds--layout-constraint--size__max-sm--51ce2";
export var cdsLayoutConstraintSize__maxXl = "Markdown-module--cds--layout-constraint--size__max-xl--faec3";
export var cdsLayoutConstraintSize__maxXs = "Markdown-module--cds--layout-constraint--size__max-xs--75496";
export var cdsLayoutConstraintSize__min2xl = "Markdown-module--cds--layout-constraint--size__min-2xl--17264";
export var cdsLayoutConstraintSize__minLg = "Markdown-module--cds--layout-constraint--size__min-lg--ed7a6";
export var cdsLayoutConstraintSize__minMd = "Markdown-module--cds--layout-constraint--size__min-md--5c909";
export var cdsLayoutConstraintSize__minSm = "Markdown-module--cds--layout-constraint--size__min-sm--8b607";
export var cdsLayoutConstraintSize__minXl = "Markdown-module--cds--layout-constraint--size__min-xl--625b9";
export var cdsLayoutConstraintSize__minXs = "Markdown-module--cds--layout-constraint--size__min-xs--55b1f";
export var cdsLayoutDensityCondensed = "Markdown-module--cds--layout--density-condensed--69c25";
export var cdsLayoutDensityNormal = "Markdown-module--cds--layout--density-normal--3092f";
export var cdsLayoutSize2xl = "Markdown-module--cds--layout--size-2xl--eb569";
export var cdsLayoutSizeLg = "Markdown-module--cds--layout--size-lg--80526";
export var cdsLayoutSizeMd = "Markdown-module--cds--layout--size-md--d24d6";
export var cdsLayoutSizeSm = "Markdown-module--cds--layout--size-sm--c76b3";
export var cdsLayoutSizeXl = "Markdown-module--cds--layout--size-xl--31b0d";
export var cdsLayoutSizeXs = "Markdown-module--cds--layout--size-xs--e55ce";
export var cdsShowFeedback = "Markdown-module--cds--show-feedback--95c9c";
export var cdsSkeleton = "Markdown-module--cds--skeleton--98636";
export var cdsVisuallyHidden = "Markdown-module--cds--visually-hidden--5ea98";
export var h1 = "Markdown-module--h1--25ca4";
export var h2 = "Markdown-module--h2--72517";
export var h3 = "Markdown-module--h3--818ce";
export var h4 = "Markdown-module--h4--7fb23";
export var h5 = "Markdown-module--h5--f0134";
export var h6 = "Markdown-module--h6--6e8f5";
export var list = "Markdown-module--list--c29de";
export var listItem = "Markdown-module--list-item--860ea";
export var paragraph = "Markdown-module--paragraph--15b49";
export var paragraphResponsive = "Markdown-module--paragraph--responsive--a668d";
export var spacedChildren = "Markdown-module--spacedChildren--87e15";
export var sup = "Markdown-module--sup--b44c3";
export var textLeft = "Markdown-module--textLeft--43f74";
export var textRight = "Markdown-module--textRight--c8ad9";