import React, { type HTMLAttributes } from 'react';

import cn from 'clsx';
import * as css from './Markdown.module.scss';

export type LiProps = HTMLAttributes<HTMLLIElement>;

export default function Li({ children, className, ...rest }: LiProps) {
    return <li className={cn(`cds--list__item`, css.listItem, className)} {...rest} children={children} />;
}
