// extracted by mini-css-extract-plugin
export var cdsAssistiveText = "Video-module--cds--assistive-text--52214";
export var cdsHideFeedback = "Video-module--cds--hide-feedback--8a711";
export var cdsLayoutConstraintDensity__defaultCondensed = "Video-module--cds--layout-constraint--density__default-condensed--33627";
export var cdsLayoutConstraintDensity__defaultNormal = "Video-module--cds--layout-constraint--density__default-normal--84a10";
export var cdsLayoutConstraintDensity__maxCondensed = "Video-module--cds--layout-constraint--density__max-condensed--64892";
export var cdsLayoutConstraintDensity__maxNormal = "Video-module--cds--layout-constraint--density__max-normal--37672";
export var cdsLayoutConstraintDensity__minCondensed = "Video-module--cds--layout-constraint--density__min-condensed--f9943";
export var cdsLayoutConstraintDensity__minNormal = "Video-module--cds--layout-constraint--density__min-normal--38e3c";
export var cdsLayoutConstraintSize__default2xl = "Video-module--cds--layout-constraint--size__default-2xl--70a99";
export var cdsLayoutConstraintSize__defaultLg = "Video-module--cds--layout-constraint--size__default-lg--c69aa";
export var cdsLayoutConstraintSize__defaultMd = "Video-module--cds--layout-constraint--size__default-md--6d092";
export var cdsLayoutConstraintSize__defaultSm = "Video-module--cds--layout-constraint--size__default-sm--30370";
export var cdsLayoutConstraintSize__defaultXl = "Video-module--cds--layout-constraint--size__default-xl--2175e";
export var cdsLayoutConstraintSize__defaultXs = "Video-module--cds--layout-constraint--size__default-xs--0666f";
export var cdsLayoutConstraintSize__max2xl = "Video-module--cds--layout-constraint--size__max-2xl--2f46b";
export var cdsLayoutConstraintSize__maxLg = "Video-module--cds--layout-constraint--size__max-lg--a931f";
export var cdsLayoutConstraintSize__maxMd = "Video-module--cds--layout-constraint--size__max-md--f8550";
export var cdsLayoutConstraintSize__maxSm = "Video-module--cds--layout-constraint--size__max-sm--f44d2";
export var cdsLayoutConstraintSize__maxXl = "Video-module--cds--layout-constraint--size__max-xl--931ba";
export var cdsLayoutConstraintSize__maxXs = "Video-module--cds--layout-constraint--size__max-xs--ff2b2";
export var cdsLayoutConstraintSize__min2xl = "Video-module--cds--layout-constraint--size__min-2xl--d6e6d";
export var cdsLayoutConstraintSize__minLg = "Video-module--cds--layout-constraint--size__min-lg--d7f76";
export var cdsLayoutConstraintSize__minMd = "Video-module--cds--layout-constraint--size__min-md--86548";
export var cdsLayoutConstraintSize__minSm = "Video-module--cds--layout-constraint--size__min-sm--fa1ff";
export var cdsLayoutConstraintSize__minXl = "Video-module--cds--layout-constraint--size__min-xl--37561";
export var cdsLayoutConstraintSize__minXs = "Video-module--cds--layout-constraint--size__min-xs--a09c5";
export var cdsLayoutDensityCondensed = "Video-module--cds--layout--density-condensed--a7aab";
export var cdsLayoutDensityNormal = "Video-module--cds--layout--density-normal--72162";
export var cdsLayoutSize2xl = "Video-module--cds--layout--size-2xl--bc7c0";
export var cdsLayoutSizeLg = "Video-module--cds--layout--size-lg--627ee";
export var cdsLayoutSizeMd = "Video-module--cds--layout--size-md--fb149";
export var cdsLayoutSizeSm = "Video-module--cds--layout--size-sm--850e2";
export var cdsLayoutSizeXl = "Video-module--cds--layout--size-xl--79f34";
export var cdsLayoutSizeXs = "Video-module--cds--layout--size-xs--00123";
export var cdsShowFeedback = "Video-module--cds--show-feedback--c3b68";
export var cdsSkeleton = "Video-module--cds--skeleton--48bac";
export var cdsVisuallyHidden = "Video-module--cds--visually-hidden--eb6a9";
export var video = "Video-module--video--2ee51";
export var videoButton = "Video-module--video-button--696e1";
export var videoContainer = "Video-module--video-container--dbd28";
export var videoIsPlaying = "Video-module--video--is-playing--e8cdc";
export var vimeo = "Video-module--vimeo--f492c";