// extracted by mini-css-extract-plugin
export var aside = "Aside-module--aside--3e9e6";
export var asideNoRule = "Aside-module--asideNoRule--74628";
export var cdsAssistiveText = "Aside-module--cds--assistive-text--bfd4d";
export var cdsHideFeedback = "Aside-module--cds--hide-feedback--bb745";
export var cdsLayoutConstraintDensity__defaultCondensed = "Aside-module--cds--layout-constraint--density__default-condensed--cc95a";
export var cdsLayoutConstraintDensity__defaultNormal = "Aside-module--cds--layout-constraint--density__default-normal--0e27e";
export var cdsLayoutConstraintDensity__maxCondensed = "Aside-module--cds--layout-constraint--density__max-condensed--cc2c0";
export var cdsLayoutConstraintDensity__maxNormal = "Aside-module--cds--layout-constraint--density__max-normal--0acce";
export var cdsLayoutConstraintDensity__minCondensed = "Aside-module--cds--layout-constraint--density__min-condensed--447b9";
export var cdsLayoutConstraintDensity__minNormal = "Aside-module--cds--layout-constraint--density__min-normal--ef611";
export var cdsLayoutConstraintSize__default2xl = "Aside-module--cds--layout-constraint--size__default-2xl--56944";
export var cdsLayoutConstraintSize__defaultLg = "Aside-module--cds--layout-constraint--size__default-lg--009b1";
export var cdsLayoutConstraintSize__defaultMd = "Aside-module--cds--layout-constraint--size__default-md--25fde";
export var cdsLayoutConstraintSize__defaultSm = "Aside-module--cds--layout-constraint--size__default-sm--e9ac0";
export var cdsLayoutConstraintSize__defaultXl = "Aside-module--cds--layout-constraint--size__default-xl--e328d";
export var cdsLayoutConstraintSize__defaultXs = "Aside-module--cds--layout-constraint--size__default-xs--0ef73";
export var cdsLayoutConstraintSize__max2xl = "Aside-module--cds--layout-constraint--size__max-2xl--c5223";
export var cdsLayoutConstraintSize__maxLg = "Aside-module--cds--layout-constraint--size__max-lg--af18e";
export var cdsLayoutConstraintSize__maxMd = "Aside-module--cds--layout-constraint--size__max-md--b4a8b";
export var cdsLayoutConstraintSize__maxSm = "Aside-module--cds--layout-constraint--size__max-sm--2a750";
export var cdsLayoutConstraintSize__maxXl = "Aside-module--cds--layout-constraint--size__max-xl--00f1c";
export var cdsLayoutConstraintSize__maxXs = "Aside-module--cds--layout-constraint--size__max-xs--050f3";
export var cdsLayoutConstraintSize__min2xl = "Aside-module--cds--layout-constraint--size__min-2xl--75184";
export var cdsLayoutConstraintSize__minLg = "Aside-module--cds--layout-constraint--size__min-lg--7a83d";
export var cdsLayoutConstraintSize__minMd = "Aside-module--cds--layout-constraint--size__min-md--c6c18";
export var cdsLayoutConstraintSize__minSm = "Aside-module--cds--layout-constraint--size__min-sm--97fa0";
export var cdsLayoutConstraintSize__minXl = "Aside-module--cds--layout-constraint--size__min-xl--5d89b";
export var cdsLayoutConstraintSize__minXs = "Aside-module--cds--layout-constraint--size__min-xs--44d9b";
export var cdsLayoutDensityCondensed = "Aside-module--cds--layout--density-condensed--d9376";
export var cdsLayoutDensityNormal = "Aside-module--cds--layout--density-normal--f63b5";
export var cdsLayoutSize2xl = "Aside-module--cds--layout--size-2xl--02e7e";
export var cdsLayoutSizeLg = "Aside-module--cds--layout--size-lg--c7161";
export var cdsLayoutSizeMd = "Aside-module--cds--layout--size-md--dc36d";
export var cdsLayoutSizeSm = "Aside-module--cds--layout--size-sm--83ecd";
export var cdsLayoutSizeXl = "Aside-module--cds--layout--size-xl--d65e7";
export var cdsLayoutSizeXs = "Aside-module--cds--layout--size-xs--685fe";
export var cdsShowFeedback = "Aside-module--cds--show-feedback--83c47";
export var cdsSkeleton = "Aside-module--cds--skeleton--6052b";
export var cdsVisuallyHidden = "Aside-module--cds--visually-hidden--c114a";