import React, { type HTMLAttributes } from 'react';
import AutolinkHeader from '../AutolinkHeader';

import cx from 'clsx';
import * as css from './Markdown.module.scss';

export type TitleProps = HTMLAttributes<HTMLHeadingElement>;
type FcElement = (props: TitleProps) => ReactElement;
export function makeTitle(level: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'): FcElement {
    const fn = ({ children, className, ...rest }: TitleProps): ReactElement => {
        return <AutolinkHeader {...rest} className={cx(css[level], className)} is={level} children={children} />;
    };
    fn.displayName = level.toUpperCase();

    return fn;
}

export const H1 = makeTitle('h1');
export const H2 = makeTitle('h2');
export const H3 = makeTitle('h3');
export const H4 = makeTitle('h4');
export const H5 = makeTitle('h5');
export const H6 = makeTitle('h5');

export { default as Ol } from './Ol';
export { default as P } from './P';
export { default as Ul } from './Ul';
export { default as Li } from './Li';
export { default as Blockquote } from './Blockquote';
