import React from 'react';
import cx from 'clsx';
import * as css from './Markdown.module.scss';

export type UlProps = {
    children?: ReactNode;
    className?: string;
};
export default function Ul({ className, ...rest }: UlProps): ReactElement {
    const classNames = cx('cds--list--unordered', css.list, className);
    return <ul {...rest} className={classNames} />;
}
