// extracted by mini-css-extract-plugin
export var cdsAssistiveText = "InlineNotification-module--cds--assistive-text--d4a86";
export var cdsHideFeedback = "InlineNotification-module--cds--hide-feedback--0546f";
export var cdsLayoutConstraintDensity__defaultCondensed = "InlineNotification-module--cds--layout-constraint--density__default-condensed--623d1";
export var cdsLayoutConstraintDensity__defaultNormal = "InlineNotification-module--cds--layout-constraint--density__default-normal--aedf1";
export var cdsLayoutConstraintDensity__maxCondensed = "InlineNotification-module--cds--layout-constraint--density__max-condensed--a4de1";
export var cdsLayoutConstraintDensity__maxNormal = "InlineNotification-module--cds--layout-constraint--density__max-normal--a5ee8";
export var cdsLayoutConstraintDensity__minCondensed = "InlineNotification-module--cds--layout-constraint--density__min-condensed--1e71a";
export var cdsLayoutConstraintDensity__minNormal = "InlineNotification-module--cds--layout-constraint--density__min-normal--98d16";
export var cdsLayoutConstraintSize__default2xl = "InlineNotification-module--cds--layout-constraint--size__default-2xl--99c7e";
export var cdsLayoutConstraintSize__defaultLg = "InlineNotification-module--cds--layout-constraint--size__default-lg--e79f8";
export var cdsLayoutConstraintSize__defaultMd = "InlineNotification-module--cds--layout-constraint--size__default-md--a820f";
export var cdsLayoutConstraintSize__defaultSm = "InlineNotification-module--cds--layout-constraint--size__default-sm--da8ac";
export var cdsLayoutConstraintSize__defaultXl = "InlineNotification-module--cds--layout-constraint--size__default-xl--b98f4";
export var cdsLayoutConstraintSize__defaultXs = "InlineNotification-module--cds--layout-constraint--size__default-xs--b731f";
export var cdsLayoutConstraintSize__max2xl = "InlineNotification-module--cds--layout-constraint--size__max-2xl--a0a28";
export var cdsLayoutConstraintSize__maxLg = "InlineNotification-module--cds--layout-constraint--size__max-lg--b056b";
export var cdsLayoutConstraintSize__maxMd = "InlineNotification-module--cds--layout-constraint--size__max-md--ae23a";
export var cdsLayoutConstraintSize__maxSm = "InlineNotification-module--cds--layout-constraint--size__max-sm--a5207";
export var cdsLayoutConstraintSize__maxXl = "InlineNotification-module--cds--layout-constraint--size__max-xl--ed987";
export var cdsLayoutConstraintSize__maxXs = "InlineNotification-module--cds--layout-constraint--size__max-xs--2aee2";
export var cdsLayoutConstraintSize__min2xl = "InlineNotification-module--cds--layout-constraint--size__min-2xl--b6090";
export var cdsLayoutConstraintSize__minLg = "InlineNotification-module--cds--layout-constraint--size__min-lg--d4f0f";
export var cdsLayoutConstraintSize__minMd = "InlineNotification-module--cds--layout-constraint--size__min-md--21294";
export var cdsLayoutConstraintSize__minSm = "InlineNotification-module--cds--layout-constraint--size__min-sm--eb04b";
export var cdsLayoutConstraintSize__minXl = "InlineNotification-module--cds--layout-constraint--size__min-xl--92bf3";
export var cdsLayoutConstraintSize__minXs = "InlineNotification-module--cds--layout-constraint--size__min-xs--fc161";
export var cdsLayoutDensityCondensed = "InlineNotification-module--cds--layout--density-condensed--48c08";
export var cdsLayoutDensityNormal = "InlineNotification-module--cds--layout--density-normal--9b094";
export var cdsLayoutSize2xl = "InlineNotification-module--cds--layout--size-2xl--1f0ab";
export var cdsLayoutSizeLg = "InlineNotification-module--cds--layout--size-lg--2cd28";
export var cdsLayoutSizeMd = "InlineNotification-module--cds--layout--size-md--76050";
export var cdsLayoutSizeSm = "InlineNotification-module--cds--layout--size-sm--4832c";
export var cdsLayoutSizeXl = "InlineNotification-module--cds--layout--size-xl--8b05a";
export var cdsLayoutSizeXs = "InlineNotification-module--cds--layout--size-xs--4d0ec";
export var cdsShowFeedback = "InlineNotification-module--cds--show-feedback--04105";
export var cdsSkeleton = "InlineNotification-module--cds--skeleton--12c15";
export var cdsVisuallyHidden = "InlineNotification-module--cds--visually-hidden--1eb96";
export var darkMode = "InlineNotification-module--darkMode--8d376";
export var notification = "InlineNotification-module--notification--8a41f";