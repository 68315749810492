import $slugify from 'slugify';

export function itemIsActive(
    path: string,
    pathname: string,
    /**
     * @example itemIsActive('/en/insights/about', '/en/insights/api', 2) === true
     * @example itemIsActive('/en/insights/about', '/en/insights/api', 3) === false
     */
    depth: number,
): boolean {
    const refPath: string = pathname.split('/').filter(Boolean).slice(0, depth).join('/');
    return path.split('/').filter(Boolean).slice(0, depth).join('/') === refPath;
}

export function pathUnslash(path: string): string {
    return (
        path
            // leading
            .replace(/^\//, '')
            // trailing
            .replace(/\/$/, '')
    );
}

export function pathSlash(path: string, head?: boolean, end?: boolean): string {
    let res = pathUnslash(path);
    if (head) res = `/${res}`;
    if (end) res = `${res}/`;
    return res;
}

export function parsePathname(
    pathname: string,
    conf?: {
        langKey?: string;
        pathPrefix?: string;
    },
): { lang: string; slug: string; path: string; segments: string[] } {
    const segments = pathname
        // Remove the path preffix
        .replace(conf?.pathPrefix ?? '', '')
        // Split into segments & remove empty ones
        .split('/')
        .filter(Boolean);

    const [lang, ...rest] = segments;

    return {
        lang,
        segments,
        slug: pathUnslash(rest.join('/').replace(/\/+/g, '/')),
        path: `/${[lang, ...rest].join('/')}`.replace(/\/+/g, '/'),
    };
}

export function stringToLinkableID(str: string): string {
    const res = slugify(str).split('-').filter(Boolean).slice(0, 10).join('-');
    return encodeURIComponent(res);
}

export function slugify(input: string): string {
    return $slugify(input, {
        lower: true,
        trim: true,
        strict: true,
        remove: /["'+?!()/]/g,
        replacement: '-',
    });
}
