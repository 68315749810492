// extracted by mini-css-extract-plugin
export var cardGroup = "CardGroup-module--cardGroup--d53b6";
export var cdsAssistiveText = "CardGroup-module--cds--assistive-text--e7a2b";
export var cdsHideFeedback = "CardGroup-module--cds--hide-feedback--f97c4";
export var cdsLayoutConstraintDensity__defaultCondensed = "CardGroup-module--cds--layout-constraint--density__default-condensed--ed5c0";
export var cdsLayoutConstraintDensity__defaultNormal = "CardGroup-module--cds--layout-constraint--density__default-normal--02226";
export var cdsLayoutConstraintDensity__maxCondensed = "CardGroup-module--cds--layout-constraint--density__max-condensed--fb1f3";
export var cdsLayoutConstraintDensity__maxNormal = "CardGroup-module--cds--layout-constraint--density__max-normal--6e152";
export var cdsLayoutConstraintDensity__minCondensed = "CardGroup-module--cds--layout-constraint--density__min-condensed--6d446";
export var cdsLayoutConstraintDensity__minNormal = "CardGroup-module--cds--layout-constraint--density__min-normal--ef928";
export var cdsLayoutConstraintSize__default2xl = "CardGroup-module--cds--layout-constraint--size__default-2xl--f47a3";
export var cdsLayoutConstraintSize__defaultLg = "CardGroup-module--cds--layout-constraint--size__default-lg--a5a10";
export var cdsLayoutConstraintSize__defaultMd = "CardGroup-module--cds--layout-constraint--size__default-md--5fddd";
export var cdsLayoutConstraintSize__defaultSm = "CardGroup-module--cds--layout-constraint--size__default-sm--0944d";
export var cdsLayoutConstraintSize__defaultXl = "CardGroup-module--cds--layout-constraint--size__default-xl--70338";
export var cdsLayoutConstraintSize__defaultXs = "CardGroup-module--cds--layout-constraint--size__default-xs--48f19";
export var cdsLayoutConstraintSize__max2xl = "CardGroup-module--cds--layout-constraint--size__max-2xl--fdf71";
export var cdsLayoutConstraintSize__maxLg = "CardGroup-module--cds--layout-constraint--size__max-lg--0af77";
export var cdsLayoutConstraintSize__maxMd = "CardGroup-module--cds--layout-constraint--size__max-md--2e6df";
export var cdsLayoutConstraintSize__maxSm = "CardGroup-module--cds--layout-constraint--size__max-sm--ec25d";
export var cdsLayoutConstraintSize__maxXl = "CardGroup-module--cds--layout-constraint--size__max-xl--adb95";
export var cdsLayoutConstraintSize__maxXs = "CardGroup-module--cds--layout-constraint--size__max-xs--837b2";
export var cdsLayoutConstraintSize__min2xl = "CardGroup-module--cds--layout-constraint--size__min-2xl--bb692";
export var cdsLayoutConstraintSize__minLg = "CardGroup-module--cds--layout-constraint--size__min-lg--d1853";
export var cdsLayoutConstraintSize__minMd = "CardGroup-module--cds--layout-constraint--size__min-md--d9f34";
export var cdsLayoutConstraintSize__minSm = "CardGroup-module--cds--layout-constraint--size__min-sm--418d8";
export var cdsLayoutConstraintSize__minXl = "CardGroup-module--cds--layout-constraint--size__min-xl--e4f65";
export var cdsLayoutConstraintSize__minXs = "CardGroup-module--cds--layout-constraint--size__min-xs--8faff";
export var cdsLayoutDensityCondensed = "CardGroup-module--cds--layout--density-condensed--77b44";
export var cdsLayoutDensityNormal = "CardGroup-module--cds--layout--density-normal--9e205";
export var cdsLayoutSize2xl = "CardGroup-module--cds--layout--size-2xl--50434";
export var cdsLayoutSizeLg = "CardGroup-module--cds--layout--size-lg--7bb5d";
export var cdsLayoutSizeMd = "CardGroup-module--cds--layout--size-md--89134";
export var cdsLayoutSizeSm = "CardGroup-module--cds--layout--size-sm--3f0ea";
export var cdsLayoutSizeXl = "CardGroup-module--cds--layout--size-xl--454cf";
export var cdsLayoutSizeXs = "CardGroup-module--cds--layout--size-xs--83153";
export var cdsShowFeedback = "CardGroup-module--cds--show-feedback--1a7b9";
export var cdsSkeleton = "CardGroup-module--cds--skeleton--9915e";
export var cdsVisuallyHidden = "CardGroup-module--cds--visually-hidden--43652";