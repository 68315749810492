import { useCallback, useRef, type ReactElement, type Ref, type RefCallback, type Component } from 'react';
import { renderToString as rts } from 'react-dom/server.browser';

export function renderToString(children: ReactNode, kind: 'html' | 'text'): string {
    const html: string = rts(children);
    if (kind === 'html') return html;

    const div = document.createElement('div');
    div.innerHTML = html;
    return div.textContent || div.innerText || '';
}

export function mergeRefs<T>(...refs: Array<Maybe<Ref<T>>>): RefCallback<T> {
    return (el: T) => {
        refs.forEach(ref => {
            if (typeof ref === 'function') ref(el);
            // @ts-expect-error: https://github.com/facebook/react/issues/13029#issuecomment-410002316
            // eslint-disable-next-line no-param-reassign
            else if (Object(ref) === ref) ref.current = el;
        });
    };
}

/**
 * Async version of `setState`.
 * Usefull when you need to wait for the state to be updated before doing something else.
 *
 * @example await setState(this, { … });
 * @example await setState(this, s => ({ … }));
 */
export function setState<State>(
    self: Component<any, State>,
    state: Partial<State> | ((prevState: State) => Partial<State>),
): Promise<void> {
    return new Promise(resolve => self.setState(state as State, resolve));
}

/**
 * Helper hook to select the text of an element when it is clicked.
 *
 * @example
 * const [ref, onClick] = useAutoSelect<HTMLSpanElement>();
 * return <span ref={ref} onClick={onClick} children="…" />;
 */
export function useAutoSelect<T extends Element>(): [ref: Ref<T>, onClick: () => void] {
    const ref = useRef<T>(null);

    const onClick = useCallback(() => {
        const selection = window.getSelection();
        const element = ref.current;
        if (selection && element) selection.setBaseAndExtent(element, 0, element, 1);
    }, []);

    return [ref, onClick];
}

export type AutoSelectedProps<T extends Element> = {
    render(p: { ref: Ref<T>; onClick(): void }): ReactElement;
};

/**
 * This component is used to wrap text that should be selectable by clicking on it.
 * @example <AutoSelected render={p => <span {...p} children="…" />} />
 */
export function AutoSelected<T extends Element>(props: AutoSelectedProps<any>) {
    const [ref, onClick] = useAutoSelect<T>();
    return props.render({ ref, onClick });
}
