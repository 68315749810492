import React, { type HTMLAttributes } from 'react';
import cn from 'clsx';
import * as css from './PageDescription.module.scss';

export interface PageDescriptionProps extends HTMLAttributes<HTMLElement> {
    children: NonNullable<ReactNode>;
}

export default function PageDescription({ children, className, ...rest }) {
    return <section {...rest} className={cn(css.pageDescription, className)} children={children} />;
}
