// extracted by mini-css-extract-plugin
export var card = "MiniCard-module--card--be23a";
export var cdsAssistiveText = "MiniCard-module--cds--assistive-text--37146";
export var cdsHideFeedback = "MiniCard-module--cds--hide-feedback--9ba29";
export var cdsLayoutConstraintDensity__defaultCondensed = "MiniCard-module--cds--layout-constraint--density__default-condensed--ebff3";
export var cdsLayoutConstraintDensity__defaultNormal = "MiniCard-module--cds--layout-constraint--density__default-normal--e3512";
export var cdsLayoutConstraintDensity__maxCondensed = "MiniCard-module--cds--layout-constraint--density__max-condensed--9fec8";
export var cdsLayoutConstraintDensity__maxNormal = "MiniCard-module--cds--layout-constraint--density__max-normal--58aeb";
export var cdsLayoutConstraintDensity__minCondensed = "MiniCard-module--cds--layout-constraint--density__min-condensed--db96c";
export var cdsLayoutConstraintDensity__minNormal = "MiniCard-module--cds--layout-constraint--density__min-normal--0011a";
export var cdsLayoutConstraintSize__default2xl = "MiniCard-module--cds--layout-constraint--size__default-2xl--dd982";
export var cdsLayoutConstraintSize__defaultLg = "MiniCard-module--cds--layout-constraint--size__default-lg--edc16";
export var cdsLayoutConstraintSize__defaultMd = "MiniCard-module--cds--layout-constraint--size__default-md--a6d9a";
export var cdsLayoutConstraintSize__defaultSm = "MiniCard-module--cds--layout-constraint--size__default-sm--bbcff";
export var cdsLayoutConstraintSize__defaultXl = "MiniCard-module--cds--layout-constraint--size__default-xl--eb929";
export var cdsLayoutConstraintSize__defaultXs = "MiniCard-module--cds--layout-constraint--size__default-xs--97245";
export var cdsLayoutConstraintSize__max2xl = "MiniCard-module--cds--layout-constraint--size__max-2xl--8ac79";
export var cdsLayoutConstraintSize__maxLg = "MiniCard-module--cds--layout-constraint--size__max-lg--a83a1";
export var cdsLayoutConstraintSize__maxMd = "MiniCard-module--cds--layout-constraint--size__max-md--f3741";
export var cdsLayoutConstraintSize__maxSm = "MiniCard-module--cds--layout-constraint--size__max-sm--5cbae";
export var cdsLayoutConstraintSize__maxXl = "MiniCard-module--cds--layout-constraint--size__max-xl--1d179";
export var cdsLayoutConstraintSize__maxXs = "MiniCard-module--cds--layout-constraint--size__max-xs--7ec22";
export var cdsLayoutConstraintSize__min2xl = "MiniCard-module--cds--layout-constraint--size__min-2xl--a2223";
export var cdsLayoutConstraintSize__minLg = "MiniCard-module--cds--layout-constraint--size__min-lg--5081f";
export var cdsLayoutConstraintSize__minMd = "MiniCard-module--cds--layout-constraint--size__min-md--255b9";
export var cdsLayoutConstraintSize__minSm = "MiniCard-module--cds--layout-constraint--size__min-sm--04549";
export var cdsLayoutConstraintSize__minXl = "MiniCard-module--cds--layout-constraint--size__min-xl--591be";
export var cdsLayoutConstraintSize__minXs = "MiniCard-module--cds--layout-constraint--size__min-xs--d30af";
export var cdsLayoutDensityCondensed = "MiniCard-module--cds--layout--density-condensed--317cc";
export var cdsLayoutDensityNormal = "MiniCard-module--cds--layout--density-normal--b76c7";
export var cdsLayoutSize2xl = "MiniCard-module--cds--layout--size-2xl--6560d";
export var cdsLayoutSizeLg = "MiniCard-module--cds--layout--size-lg--5df20";
export var cdsLayoutSizeMd = "MiniCard-module--cds--layout--size-md--6cbdf";
export var cdsLayoutSizeSm = "MiniCard-module--cds--layout--size-sm--cac41";
export var cdsLayoutSizeXl = "MiniCard-module--cds--layout--size-xl--c2d44";
export var cdsLayoutSizeXs = "MiniCard-module--cds--layout--size-xs--7b56c";
export var cdsShowFeedback = "MiniCard-module--cds--show-feedback--2ae8b";
export var cdsSkeleton = "MiniCard-module--cds--skeleton--a13ca";
export var cdsVisuallyHidden = "MiniCard-module--cds--visually-hidden--6e8be";
export var icon = "MiniCard-module--icon--457ee";
export var image = "MiniCard-module--image--1de27";
export var title = "MiniCard-module--title--17a7e";
export var wrapper = "MiniCard-module--wrapper--0cb38";