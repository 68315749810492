import React from 'react';
import useMetadata from '../../util/hooks/useMetadata';

import { CopyButton } from '@carbon/react';
import { Launch } from '@carbon/react/icons';
import copy from 'copy-to-clipboard';

import cn from 'clsx';
import * as css from './Code.module.scss';

// If no path is given, don't render. We'll use the Sidebar for buttons
// If a src url is given, the src Icon will display in this row, otherwise
// The copy button will.

export type PathRowProps = {
    src?: string;
    path?: string;
    code: string;
};

export default function PathRow({ src, path, code }: PathRowProps) {
    const { interiorTheme } = useMetadata();
    if (!path) return null;

    return (
        <div className={cn(css.pathRow, interiorTheme === 'dark' && css.dark)}>
            <span className={css.path} children={path} />
            {src ? (
                <a // eslint-disable-line gatsby/use-gatsby-link
                    target="_blank"
                    rel="noopener noreferrer"
                    title="View source"
                    className={css.button}
                    href={src}
                    style={{ position: 'relative', zIndex: 200 }}
                    children={<Launch />}
                />
            ) : (
                <CopyButton align="left" className={cn(css.button, css.copyButton)} onClick={() => copy(code)} />
            )}
        </div>
    );
}
