import React from 'react';

import { Row, Column } from '../Grid';

import cn from 'clsx';
import * as css from './ExpressiveListContainer.module.scss';

export interface ExpressiveListContainerProps {
    children?: ReactNode;

    text?: string;
    title?: string;
    titleType?: 'expressive-04' | 'expressive-02';

    className?: string;
    contentClassName?: string;
}

export default function ExpressiveListContainer(props: ExpressiveListContainerProps) {
    const { contentClassName, children, className, text, title, titleType } = props;

    return (
        <Row className={cn(className, css.container)}>
            <Column colMd={2} colLg={4} noGutterLgLeft>
                <h3
                    className={cn(
                        css.title,
                        titleType === 'expressive-04' && css.expressive_04,
                        titleType === 'expressive-02' && css.expressive_02,
                    )}
                    children={title}
                />
            </Column>
            <Column colMd={6} colLg={8} noGutterLgRight className={cn(contentClassName, css.content)}>
                <p className={css.text} children={text} />
                {children}
            </Column>
        </Row>
    );
}
