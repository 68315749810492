import React, { Fragment } from 'react';
import { Link } from 'gatsby';

import { Column } from '../Grid';
import { Launch, Download, ArrowRight, Error, Email } from '@carbon/react/icons';

import cn from 'clsx';
import * as css from './SquareCard.module.scss';

export type SquareCardProps = {
    // Use to set a pictogram
    children?: ReactNode;

    href?: string;

    title?: string; // Large heading
    actionIcon: 'launch' | 'arrowRight' | 'download' | 'error' | 'email' | 'disabled';
    bodyText?: string;

    smallTitle?: boolean;
    disabled?: boolean;
    color?: 'dark';

    helperText?: string;
    className?: string;
};

export default function SquareCard(props: SquareCardProps) {
    const {
        children,
        href,
        title,
        smallTitle,
        disabled,
        bodyText,
        helperText,
        className,
        actionIcon = 'arrowRight',
        color = 'white',
        ...rest
    } = props;

    const SquareCardClassNames = cn(
        css.squareCard,
        disabled && css.disabled,
        color === 'dark' && css.darkMode,
        className,
    );
    const SquareCardTitleClassNames = cn(css.title, smallTitle && css.titleSmall, className);
    const carbonTileclassNames = cn('cds--tile', 'cds--tile--clickable');
    const aspectRatioClassNames = cn('cds--aspect-ratio', 'cds--aspect-ratio--1x1');

    const cardContent = (
        <Fragment>
            <h2 className={SquareCardTitleClassNames} children={title} />
            {bodyText ? <p className={css.body} children={bodyText} /> : null}
            {helperText ? <p className={css.helperText} children={helperText} /> : null}
            {children ? <div className={css.helperIcon} children={children} /> : null}
            <div className={css.actionIcon}>
                {actionIcon === 'arrowRight' && !disabled ? <ArrowRight size={20} aria-label="Open resource" /> : null}
                {actionIcon === 'launch' && !disabled ? <Launch size={20} aria-label="Open resource" /> : null}
                {actionIcon === 'download' && !disabled ? <Download size={20} aria-label="Download" /> : null}
                {actionIcon === 'email' && !disabled ? <Email size={20} aria-label="Email" /> : null}
                {actionIcon === 'disabled' || disabled === true ? <Error size={20} aria-label="disabled" /> : null}
            </div>
        </Fragment>
    );

    let cardContainer;
    if (disabled === true) {
        cardContainer = <div className={carbonTileclassNames} children={cardContent} />;
    } else if (href != null && href.charAt(0) === '/') {
        cardContainer = <Link {...rest} to={href} className={carbonTileclassNames} children={cardContent} />;
    } else {
        // eslint-disable-next-line gatsby/use-gatsby-link
        cardContainer = <a {...rest} href={href} className={carbonTileclassNames} children={cardContent} />;
    }

    return (
        <Column {...rest} colMd={4} colLg={4} noGutterSm className={SquareCardClassNames}>
            <div className={aspectRatioClassNames}>
                <div className="cds--aspect-ratio--object" children={cardContainer} />
            </div>
        </Column>
    );
}
