// extracted by mini-css-extract-plugin
export var cdsAssistiveText = "PageDescription-module--cds--assistive-text--542dc";
export var cdsHideFeedback = "PageDescription-module--cds--hide-feedback--ab624";
export var cdsLayoutConstraintDensity__defaultCondensed = "PageDescription-module--cds--layout-constraint--density__default-condensed--c7a5d";
export var cdsLayoutConstraintDensity__defaultNormal = "PageDescription-module--cds--layout-constraint--density__default-normal--578f3";
export var cdsLayoutConstraintDensity__maxCondensed = "PageDescription-module--cds--layout-constraint--density__max-condensed--72b00";
export var cdsLayoutConstraintDensity__maxNormal = "PageDescription-module--cds--layout-constraint--density__max-normal--205d6";
export var cdsLayoutConstraintDensity__minCondensed = "PageDescription-module--cds--layout-constraint--density__min-condensed--bd668";
export var cdsLayoutConstraintDensity__minNormal = "PageDescription-module--cds--layout-constraint--density__min-normal--0483a";
export var cdsLayoutConstraintSize__default2xl = "PageDescription-module--cds--layout-constraint--size__default-2xl--d86a6";
export var cdsLayoutConstraintSize__defaultLg = "PageDescription-module--cds--layout-constraint--size__default-lg--8dcf0";
export var cdsLayoutConstraintSize__defaultMd = "PageDescription-module--cds--layout-constraint--size__default-md--a14c2";
export var cdsLayoutConstraintSize__defaultSm = "PageDescription-module--cds--layout-constraint--size__default-sm--1b75c";
export var cdsLayoutConstraintSize__defaultXl = "PageDescription-module--cds--layout-constraint--size__default-xl--ed791";
export var cdsLayoutConstraintSize__defaultXs = "PageDescription-module--cds--layout-constraint--size__default-xs--41452";
export var cdsLayoutConstraintSize__max2xl = "PageDescription-module--cds--layout-constraint--size__max-2xl--a8531";
export var cdsLayoutConstraintSize__maxLg = "PageDescription-module--cds--layout-constraint--size__max-lg--a081f";
export var cdsLayoutConstraintSize__maxMd = "PageDescription-module--cds--layout-constraint--size__max-md--33268";
export var cdsLayoutConstraintSize__maxSm = "PageDescription-module--cds--layout-constraint--size__max-sm--77dde";
export var cdsLayoutConstraintSize__maxXl = "PageDescription-module--cds--layout-constraint--size__max-xl--73729";
export var cdsLayoutConstraintSize__maxXs = "PageDescription-module--cds--layout-constraint--size__max-xs--0dcdb";
export var cdsLayoutConstraintSize__min2xl = "PageDescription-module--cds--layout-constraint--size__min-2xl--12a6a";
export var cdsLayoutConstraintSize__minLg = "PageDescription-module--cds--layout-constraint--size__min-lg--8a704";
export var cdsLayoutConstraintSize__minMd = "PageDescription-module--cds--layout-constraint--size__min-md--2b73d";
export var cdsLayoutConstraintSize__minSm = "PageDescription-module--cds--layout-constraint--size__min-sm--174f2";
export var cdsLayoutConstraintSize__minXl = "PageDescription-module--cds--layout-constraint--size__min-xl--4a1b0";
export var cdsLayoutConstraintSize__minXs = "PageDescription-module--cds--layout-constraint--size__min-xs--6fd0f";
export var cdsLayoutDensityCondensed = "PageDescription-module--cds--layout--density-condensed--d7307";
export var cdsLayoutDensityNormal = "PageDescription-module--cds--layout--density-normal--20bd2";
export var cdsLayoutSize2xl = "PageDescription-module--cds--layout--size-2xl--d5d2e";
export var cdsLayoutSizeLg = "PageDescription-module--cds--layout--size-lg--4238d";
export var cdsLayoutSizeMd = "PageDescription-module--cds--layout--size-md--2693d";
export var cdsLayoutSizeSm = "PageDescription-module--cds--layout--size-sm--e45d1";
export var cdsLayoutSizeXl = "PageDescription-module--cds--layout--size-xl--9662f";
export var cdsLayoutSizeXs = "PageDescription-module--cds--layout--size-xs--78f88";
export var cdsShowFeedback = "PageDescription-module--cds--show-feedback--9fcea";
export var cdsSkeleton = "PageDescription-module--cds--skeleton--7c505";
export var cdsVisuallyHidden = "PageDescription-module--cds--visually-hidden--c1adb";
export var pageDescription = "PageDescription-module--page-description--3a4b0";