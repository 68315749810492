import React, { type ComponentType } from 'react';
import useMetadata from '../../util/hooks/useMetadata';

import { ErrorFilled, CheckmarkFilled, WarningFilled, InformationFilled } from '@carbon/react/icons';
import { Row, Column } from '../Grid';

import cn from 'clsx';
import * as css from './InlineNotification.module.scss';

type IconComponent = ComponentType<{ className?: string; children: ReactNode }>;
const iconTypes = {
    error: ErrorFilled,
    success: CheckmarkFilled,
    warning: WarningFilled,
    info: InformationFilled,
} satisfies Record<InlineNotificationKind, IconComponent>;

export type InlineNotificationKind = 'error' | 'success' | 'warning' | 'info';
export type InlineNotificationProps = {
    kind?: InlineNotificationKind;
    children: ReactNode;
    className?: string;
};

export default function InlineNotification({ children, className, kind }: InlineNotificationProps) {
    const $kind = (kind || 'info') as InlineNotificationKind;
    const { interiorTheme } = useMetadata();
    const containerClassName = cn(
        `cds--inline-notification`,
        `cds--inline-notification--low-contrast`,
        `cds--inline-notification--${$kind}`,
        `cds--inline-notification--hide-close-button`,
        interiorTheme === 'dark' && css.darkMode,
        className,
    );
    const IconForKind = iconTypes[$kind] as Maybe<IconComponent>;

    return (
        <Row role="alert">
            <Column colLg={8} colMd={6} className={cn(css.notification, className)}>
                <div className={containerClassName}>
                    <div className="cds--inline-notification__details">
                        {IconForKind ? (
                            <IconForKind
                                className="cds--inline-notification__icon"
                                children={<title children={`${kind} icon`} />}
                            />
                        ) : null}
                        <div className="cds--inline-notification__text-wrapper">
                            <div className="cds--inline-notification__content" children={children} />
                        </div>
                    </div>
                </div>
            </Column>
        </Row>
    );
}
