import React from 'react';
import { slugify } from 'lib/url';
import { renderToString } from 'lib/react';

import cx from 'clsx';
import { link } from './AnchorLinks.module.scss';

export type AnchorLinkProps = {
    to?: string;
    children: string;
    className?: string;
};
export default function AnchorLink({ to, children, className }: AnchorLinkProps) {
    const childrenString: string = renderToString(children, 'text');
    const href = to || `#${slugify(childrenString)}`;

    // eslint-disable-next-line gatsby/use-gatsby-link
    return <a className={cx(link, className)} href={href} children={children} />;
}
