import { createContext, useContext } from 'react';
import * as L from 'data/languages/config.mjs';

type LangEntryGqlFull = RequiredNonNullable<Queries.SiteSiteMetadataLanguagesLangs>;
export const defaultLangEntry = L[L.defaultLangKey];

export type LangItem = {
    label: string;
    langKey: string;
    selected: boolean;
    link: string;
};

export type LangContextType = {
    default: LangEntryGqlFull;
    current: LangEntryGqlFull;
    langs: Array<LangItem>;
    messages: Record<string, string>;
    localizedLinks: Array<string>;
};
const LangContext = createContext<LangContextType>({
    default: defaultLangEntry,
    current: defaultLangEntry,
    langs: [],
    messages: {},
    localizedLinks: [],
});

export default LangContext;
export const useLang = () => useContext(LangContext);
