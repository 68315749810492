import React, { type HTMLAttributes } from 'react';

import { Row } from '../Grid';

import cn from 'clsx';
import * as css from './Markdown.module.scss';

export interface PProps extends HTMLAttributes<HTMLParagraphElement> {
    fullWidth?: boolean;
}

export default function P({ children, className, fullWidth, ...rest }: PProps) {
    const paragraphClasses = cn(css.paragraph, !fullWidth && css.paragraphResponsive, className);

    return (
        <Row>
            <p {...rest} className={paragraphClasses} children={children} />
        </Row>
    );
}
