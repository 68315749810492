import React, { type HTMLAttributes } from 'react';
import cx from 'clsx';
import { title } from './Title.module.scss';
import { h4 } from '../markdown/Markdown.module.scss';

export interface TitleProps extends HTMLAttributes<HTMLSpanElement> {
    className?: string;
}

export default function Link({ className, ...rest }: TitleProps) {
    return <span {...rest} className={cx(className, h4, title)} />;
}
