import React, { Component, Children } from 'react';

import cn from 'clsx';

export type PageTableProps = {
    children?: ReactNode;
};

export default class PageTable extends Component<PageTableProps> {
    render() {
        const [thead, tbody] = Children.toArray(this.props.children) as [thead: ReactElement, tbody: ReactElement];

        // const bodyRows = tbody.props.children;
        // const gridSize = Array.isArray(bodyRows)
        //     ? //
        //       bodyRows[0].props.children.length
        //     : //
        //       bodyRows.props.children.length;

        // gridSize < 4 && 'cds--col-lg-8 cds--col-md-6',
        // gridSize > 3 && 'cds--col-lg-12',
        const classNames = cn('cds--col-lg-12', 'cds--col-no-gutter', 'page-table__container');

        return (
            <div className="cds--row">
                <div className={classNames}>
                    <table className="page-table">
                        {thead}
                        {tbody}
                    </table>
                </div>
            </div>
        );
    }
}
