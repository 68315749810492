// extracted by mini-css-extract-plugin
export var caption = "Caption-module--caption--f064a";
export var captionResponsive = "Caption-module--caption-responsive--1289a";
export var cdsAssistiveText = "Caption-module--cds--assistive-text--a59ce";
export var cdsHideFeedback = "Caption-module--cds--hide-feedback--f367b";
export var cdsLayoutConstraintDensity__defaultCondensed = "Caption-module--cds--layout-constraint--density__default-condensed--4dbcc";
export var cdsLayoutConstraintDensity__defaultNormal = "Caption-module--cds--layout-constraint--density__default-normal--8c416";
export var cdsLayoutConstraintDensity__maxCondensed = "Caption-module--cds--layout-constraint--density__max-condensed--2ff4c";
export var cdsLayoutConstraintDensity__maxNormal = "Caption-module--cds--layout-constraint--density__max-normal--e76bc";
export var cdsLayoutConstraintDensity__minCondensed = "Caption-module--cds--layout-constraint--density__min-condensed--942a7";
export var cdsLayoutConstraintDensity__minNormal = "Caption-module--cds--layout-constraint--density__min-normal--48f0e";
export var cdsLayoutConstraintSize__default2xl = "Caption-module--cds--layout-constraint--size__default-2xl--5e8a4";
export var cdsLayoutConstraintSize__defaultLg = "Caption-module--cds--layout-constraint--size__default-lg--dc491";
export var cdsLayoutConstraintSize__defaultMd = "Caption-module--cds--layout-constraint--size__default-md--e902b";
export var cdsLayoutConstraintSize__defaultSm = "Caption-module--cds--layout-constraint--size__default-sm--d1707";
export var cdsLayoutConstraintSize__defaultXl = "Caption-module--cds--layout-constraint--size__default-xl--b57f2";
export var cdsLayoutConstraintSize__defaultXs = "Caption-module--cds--layout-constraint--size__default-xs--d2ed2";
export var cdsLayoutConstraintSize__max2xl = "Caption-module--cds--layout-constraint--size__max-2xl--36773";
export var cdsLayoutConstraintSize__maxLg = "Caption-module--cds--layout-constraint--size__max-lg--06355";
export var cdsLayoutConstraintSize__maxMd = "Caption-module--cds--layout-constraint--size__max-md--ca75f";
export var cdsLayoutConstraintSize__maxSm = "Caption-module--cds--layout-constraint--size__max-sm--136db";
export var cdsLayoutConstraintSize__maxXl = "Caption-module--cds--layout-constraint--size__max-xl--f702f";
export var cdsLayoutConstraintSize__maxXs = "Caption-module--cds--layout-constraint--size__max-xs--f9f00";
export var cdsLayoutConstraintSize__min2xl = "Caption-module--cds--layout-constraint--size__min-2xl--1502a";
export var cdsLayoutConstraintSize__minLg = "Caption-module--cds--layout-constraint--size__min-lg--84a90";
export var cdsLayoutConstraintSize__minMd = "Caption-module--cds--layout-constraint--size__min-md--81d33";
export var cdsLayoutConstraintSize__minSm = "Caption-module--cds--layout-constraint--size__min-sm--9f34d";
export var cdsLayoutConstraintSize__minXl = "Caption-module--cds--layout-constraint--size__min-xl--05a1e";
export var cdsLayoutConstraintSize__minXs = "Caption-module--cds--layout-constraint--size__min-xs--a0a54";
export var cdsLayoutDensityCondensed = "Caption-module--cds--layout--density-condensed--ecd5d";
export var cdsLayoutDensityNormal = "Caption-module--cds--layout--density-normal--7b877";
export var cdsLayoutSize2xl = "Caption-module--cds--layout--size-2xl--9ae40";
export var cdsLayoutSizeLg = "Caption-module--cds--layout--size-lg--e275c";
export var cdsLayoutSizeMd = "Caption-module--cds--layout--size-md--4aa99";
export var cdsLayoutSizeSm = "Caption-module--cds--layout--size-sm--70558";
export var cdsLayoutSizeXl = "Caption-module--cds--layout--size-xl--5b3f3";
export var cdsLayoutSizeXs = "Caption-module--cds--layout--size-xs--cca73";
export var cdsShowFeedback = "Caption-module--cds--show-feedback--0b1c1";
export var cdsSkeleton = "Caption-module--cds--skeleton--bc9a6";
export var cdsVisuallyHidden = "Caption-module--cds--visually-hidden--46afa";