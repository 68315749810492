import React, { Component } from 'react';

import { Link } from 'gatsby';
import { Launch, Download, ArrowRight, Error, Email } from '@carbon/react/icons';

import cx from 'clsx';

export type ArticleCardProps = {
    children?: ReactNode;

    href?: string;
    title?: string;
    subTitle?: string;
    author?: string;
    date?: string;
    readTime?: string;
    actionIcon?: string;
    color?: 'white' | 'dark';

    disabled?: boolean;
    className?: string;
};

export default class ArticleCard extends Component<ArticleCardProps> {
    static defaultProps = {
        color: 'white',
        disabled: false,
        actionIcon: '',
    };

    render() {
        const {
            children,
            href,
            title,
            subTitle,
            author,
            date,
            readTime,
            color,
            disabled,
            actionIcon,
            className,
            ...rest
        } = this.props;

        // const prefix = 'cds';

        const ArticleCardClassNames = cx(
            className,
            `cds--article-card`,
            disabled && `cds--article-card--disabled`,
            color === 'dark' && `cds--article-card--dark`,
        );

        const aspectRatioClassNames = cx('cds--aspect-ratio', 'cds--aspect-ratio--2x1');
        const cardContentClassNames = cx('cds--tile', 'cds--tile--clickable', 'cds--article-card_content');

        const cardContent = (
            <>
                <div className="cds--article-card__img" children={children} />
                <div className={aspectRatioClassNames}>
                    <div className="cds--aspect-ratio--object cds--article-card__tile">
                        {subTitle ? <h5 className="cds--article-card__subtitle" children={subTitle} /> : null}
                        {title ? <h4 className="cds--article-card__title" children={title} /> : null}
                        <div className="cds--article-card__info">
                            {author ? <p className="cds--article-card__author" children={author} /> : null}
                            {date ? <p className="cds--article-card__date" children={date} /> : null}
                            {readTime ? <p className="cds--article-card__read-time" children={readTime} /> : null}
                        </div>
                        <div className="cds--article-card__icon--action">
                            {actionIcon === 'launch' && !disabled ? <Launch size={20} aria-label="Open" /> : null}
                            {actionIcon === 'arrowRight' && !disabled ? (
                                <ArrowRight size={20} aria-label="Open" />
                            ) : null}
                            {actionIcon === 'download' && !disabled ? (
                                <Download size={20} aria-label="Download" />
                            ) : null}
                            {actionIcon === 'email' && !disabled ? <Email size={20} aria-label="Email" /> : null}
                            {actionIcon === 'disabled' || disabled === true ? (
                                <Error size={20} aria-label="disabled" />
                            ) : null}
                        </div>
                    </div>
                </div>
            </>
        );

        let cardContainer;
        if (disabled === true) {
            cardContainer = <div className={cardContentClassNames} children={cardContent} />;
        } else if (href?.charAt(0) === '/') {
            cardContainer = <Link to={href} className={cardContentClassNames} {...rest} children={cardContent} />;
        } else {
            // eslint-disable-next-line gatsby/use-gatsby-link
            cardContainer = <a href={href} className={cardContentClassNames} {...rest} children={cardContent} />;
        }

        return <div className={ArticleCardClassNames} children={cardContainer} />;
    }
}
