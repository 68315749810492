import React, { Component } from 'react';
import cx from 'clsx';

import { Link, withPrefix } from 'gatsby';
import { AspectRatio } from '@carbon/react';
import { ArrowRight, Calendar, Download, Email, Error, Launch } from '@carbon/react/icons';

export type ResourceCardProps = {
    children?: ReactNode;
    href?: string;

    title?: string;
    subTitle?: string;

    actionIcon: 'launch' | 'arrowRight' | 'download' | 'email' | 'calendar' | 'disabled';
    aspectRatio?: '1:1' | '2:1' | '16:9' | '4:3';
    download?: boolean;

    color?: 'white' | 'dark';
    disabled?: boolean;
    className?: string;
};

export default class ResourceCard extends Component<ResourceCardProps> {
    render() {
        const {
            children,
            href,
            title,
            subTitle,
            color = 'white',
            disabled,
            aspectRatio = '2:1',
            actionIcon = 'launch',
            className,
            ...rest
        } = this.props;

        let isLink;
        if (href !== undefined && !rest.download) {
            isLink = href.charAt(0) === '/';
        }

        const ResourceCardClassNames = cx(
            'cds--resource-card',
            disabled && 'cds--resource-card--disabled',
            color === 'dark' && `cds--resource-card--dark`,
            className,
        );
        const carbonTileclassNames = cx('cds--tile', href != null && 'cds--tile--clickable');

        const cardContent = (
            <>
                {subTitle && <h5 className="cds--resource-card__subtitle">{subTitle}</h5>}
                {title && <h4 className="cds--resource-card__title" children={title} />}
                <div className="cds--resource-card__icon--img" children={children} />
                <div className="cds--resource-card__icon--action">
                    {actionIcon === 'launch' && !disabled ? <Launch size={20} aria-label="Open resource" /> : null}
                    {actionIcon === 'arrowRight' && !disabled ? (
                        <ArrowRight size={20} aria-label="Open resource" />
                    ) : null}
                    {actionIcon === 'download' && !disabled ? <Download size={20} aria-label="Download" /> : null}
                    {actionIcon === 'email' && !disabled ? <Email size={20} aria-label="Email" /> : null}
                    {actionIcon === 'calendar' && !disabled ? <Calendar size={20} aria-label="Calendar" /> : null}
                    {actionIcon === 'disabled' || disabled === true ? <Error size={20} aria-label="disabled" /> : null}
                </div>
            </>
        );

        let cardContainer;
        if (disabled === true || href === undefined) {
            cardContainer = <div className={carbonTileclassNames}>{cardContent}</div>;
        } else if (isLink === true) {
            cardContainer = <Link to={href} className={carbonTileclassNames} {...rest} children={cardContent} />;
        } else {
            // The URL is assumed to be external if an http protocol is present or defaulted (i.e. it is a protocal-relative URL).
            const isExternalURL = RegExp(/^(https?:)?\/\//g).test(href);

            // Prepend the path prefix in production.
            const hrefPrefixed = withPrefix(href);

            cardContainer = (
                // eslint-disable-next-line gatsby/use-gatsby-link
                <a
                    href={rest.download && !isExternalURL ? hrefPrefixed : href}
                    className={carbonTileclassNames}
                    {...rest}
                    children={cardContent}
                />
            );
        }

        return (
            <div className={ResourceCardClassNames}>
                <AspectRatio
                    // @ts-expect-error: Known type impression inherited from IBM
                    ratio={`${aspectRatio.replace(':', 'x')}`}
                    children={<div className="cds--aspect-ratio--object" children={cardContainer} />}
                />
            </div>
        );
    }
}
