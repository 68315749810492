// extracted by mini-css-extract-plugin
export var actionIcon = "SquareCard-module--actionIcon--1628d";
export var body = "SquareCard-module--body--76c36";
export var cdsAssistiveText = "SquareCard-module--cds--assistive-text--b55db";
export var cdsHideFeedback = "SquareCard-module--cds--hide-feedback--9d2cb";
export var cdsLayoutConstraintDensity__defaultCondensed = "SquareCard-module--cds--layout-constraint--density__default-condensed--e7237";
export var cdsLayoutConstraintDensity__defaultNormal = "SquareCard-module--cds--layout-constraint--density__default-normal--ed1fd";
export var cdsLayoutConstraintDensity__maxCondensed = "SquareCard-module--cds--layout-constraint--density__max-condensed--e5c09";
export var cdsLayoutConstraintDensity__maxNormal = "SquareCard-module--cds--layout-constraint--density__max-normal--22e86";
export var cdsLayoutConstraintDensity__minCondensed = "SquareCard-module--cds--layout-constraint--density__min-condensed--56a95";
export var cdsLayoutConstraintDensity__minNormal = "SquareCard-module--cds--layout-constraint--density__min-normal--ac060";
export var cdsLayoutConstraintSize__default2xl = "SquareCard-module--cds--layout-constraint--size__default-2xl--2676c";
export var cdsLayoutConstraintSize__defaultLg = "SquareCard-module--cds--layout-constraint--size__default-lg--40a2c";
export var cdsLayoutConstraintSize__defaultMd = "SquareCard-module--cds--layout-constraint--size__default-md--fea5a";
export var cdsLayoutConstraintSize__defaultSm = "SquareCard-module--cds--layout-constraint--size__default-sm--e3b13";
export var cdsLayoutConstraintSize__defaultXl = "SquareCard-module--cds--layout-constraint--size__default-xl--458a4";
export var cdsLayoutConstraintSize__defaultXs = "SquareCard-module--cds--layout-constraint--size__default-xs--77650";
export var cdsLayoutConstraintSize__max2xl = "SquareCard-module--cds--layout-constraint--size__max-2xl--9d0e4";
export var cdsLayoutConstraintSize__maxLg = "SquareCard-module--cds--layout-constraint--size__max-lg--4abe2";
export var cdsLayoutConstraintSize__maxMd = "SquareCard-module--cds--layout-constraint--size__max-md--7b797";
export var cdsLayoutConstraintSize__maxSm = "SquareCard-module--cds--layout-constraint--size__max-sm--8304b";
export var cdsLayoutConstraintSize__maxXl = "SquareCard-module--cds--layout-constraint--size__max-xl--bd281";
export var cdsLayoutConstraintSize__maxXs = "SquareCard-module--cds--layout-constraint--size__max-xs--41cb7";
export var cdsLayoutConstraintSize__min2xl = "SquareCard-module--cds--layout-constraint--size__min-2xl--699cf";
export var cdsLayoutConstraintSize__minLg = "SquareCard-module--cds--layout-constraint--size__min-lg--aeaff";
export var cdsLayoutConstraintSize__minMd = "SquareCard-module--cds--layout-constraint--size__min-md--a7a14";
export var cdsLayoutConstraintSize__minSm = "SquareCard-module--cds--layout-constraint--size__min-sm--a0774";
export var cdsLayoutConstraintSize__minXl = "SquareCard-module--cds--layout-constraint--size__min-xl--bb1aa";
export var cdsLayoutConstraintSize__minXs = "SquareCard-module--cds--layout-constraint--size__min-xs--20b2d";
export var cdsLayoutDensityCondensed = "SquareCard-module--cds--layout--density-condensed--5cd8d";
export var cdsLayoutDensityNormal = "SquareCard-module--cds--layout--density-normal--e9931";
export var cdsLayoutSize2xl = "SquareCard-module--cds--layout--size-2xl--dd1e8";
export var cdsLayoutSizeLg = "SquareCard-module--cds--layout--size-lg--6fc11";
export var cdsLayoutSizeMd = "SquareCard-module--cds--layout--size-md--717ca";
export var cdsLayoutSizeSm = "SquareCard-module--cds--layout--size-sm--4d77f";
export var cdsLayoutSizeXl = "SquareCard-module--cds--layout--size-xl--17281";
export var cdsLayoutSizeXs = "SquareCard-module--cds--layout--size-xs--b0459";
export var cdsShowFeedback = "SquareCard-module--cds--show-feedback--2d518";
export var cdsSkeleton = "SquareCard-module--cds--skeleton--789cd";
export var cdsVisuallyHidden = "SquareCard-module--cds--visually-hidden--5f60d";
export var darkMode = "SquareCard-module--darkMode--011fb";
export var disabled = "SquareCard-module--disabled--f9c37";
export var helperIcon = "SquareCard-module--helperIcon--f6d31";
export var helperText = "SquareCard-module--helperText--4ebdd";
export var squareCard = "SquareCard-module--squareCard--029b6";
export var title = "SquareCard-module--title--c4e3c";
export var titleSmall = "SquareCard-module--titleSmall--b675c";