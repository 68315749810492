// extracted by mini-css-extract-plugin
export var anchor = "AutolinkHeader-module--anchor--096b5";
export var cdsAssistiveText = "AutolinkHeader-module--cds--assistive-text--8b09c";
export var cdsHideFeedback = "AutolinkHeader-module--cds--hide-feedback--094a4";
export var cdsLayoutConstraintDensity__defaultCondensed = "AutolinkHeader-module--cds--layout-constraint--density__default-condensed--db793";
export var cdsLayoutConstraintDensity__defaultNormal = "AutolinkHeader-module--cds--layout-constraint--density__default-normal--9b157";
export var cdsLayoutConstraintDensity__maxCondensed = "AutolinkHeader-module--cds--layout-constraint--density__max-condensed--22343";
export var cdsLayoutConstraintDensity__maxNormal = "AutolinkHeader-module--cds--layout-constraint--density__max-normal--b8e63";
export var cdsLayoutConstraintDensity__minCondensed = "AutolinkHeader-module--cds--layout-constraint--density__min-condensed--e15d5";
export var cdsLayoutConstraintDensity__minNormal = "AutolinkHeader-module--cds--layout-constraint--density__min-normal--e8577";
export var cdsLayoutConstraintSize__default2xl = "AutolinkHeader-module--cds--layout-constraint--size__default-2xl--b11f4";
export var cdsLayoutConstraintSize__defaultLg = "AutolinkHeader-module--cds--layout-constraint--size__default-lg--4474f";
export var cdsLayoutConstraintSize__defaultMd = "AutolinkHeader-module--cds--layout-constraint--size__default-md--e6212";
export var cdsLayoutConstraintSize__defaultSm = "AutolinkHeader-module--cds--layout-constraint--size__default-sm--ef395";
export var cdsLayoutConstraintSize__defaultXl = "AutolinkHeader-module--cds--layout-constraint--size__default-xl--104e6";
export var cdsLayoutConstraintSize__defaultXs = "AutolinkHeader-module--cds--layout-constraint--size__default-xs--7bc3a";
export var cdsLayoutConstraintSize__max2xl = "AutolinkHeader-module--cds--layout-constraint--size__max-2xl--c95a6";
export var cdsLayoutConstraintSize__maxLg = "AutolinkHeader-module--cds--layout-constraint--size__max-lg--d0183";
export var cdsLayoutConstraintSize__maxMd = "AutolinkHeader-module--cds--layout-constraint--size__max-md--181ca";
export var cdsLayoutConstraintSize__maxSm = "AutolinkHeader-module--cds--layout-constraint--size__max-sm--e1068";
export var cdsLayoutConstraintSize__maxXl = "AutolinkHeader-module--cds--layout-constraint--size__max-xl--3b2ef";
export var cdsLayoutConstraintSize__maxXs = "AutolinkHeader-module--cds--layout-constraint--size__max-xs--c56ad";
export var cdsLayoutConstraintSize__min2xl = "AutolinkHeader-module--cds--layout-constraint--size__min-2xl--4b63e";
export var cdsLayoutConstraintSize__minLg = "AutolinkHeader-module--cds--layout-constraint--size__min-lg--bbfad";
export var cdsLayoutConstraintSize__minMd = "AutolinkHeader-module--cds--layout-constraint--size__min-md--68848";
export var cdsLayoutConstraintSize__minSm = "AutolinkHeader-module--cds--layout-constraint--size__min-sm--f759f";
export var cdsLayoutConstraintSize__minXl = "AutolinkHeader-module--cds--layout-constraint--size__min-xl--7d910";
export var cdsLayoutConstraintSize__minXs = "AutolinkHeader-module--cds--layout-constraint--size__min-xs--a76e1";
export var cdsLayoutDensityCondensed = "AutolinkHeader-module--cds--layout--density-condensed--39293";
export var cdsLayoutDensityNormal = "AutolinkHeader-module--cds--layout--density-normal--1e6e8";
export var cdsLayoutSize2xl = "AutolinkHeader-module--cds--layout--size-2xl--662bf";
export var cdsLayoutSizeLg = "AutolinkHeader-module--cds--layout--size-lg--7c428";
export var cdsLayoutSizeMd = "AutolinkHeader-module--cds--layout--size-md--6f1ba";
export var cdsLayoutSizeSm = "AutolinkHeader-module--cds--layout--size-sm--f7800";
export var cdsLayoutSizeXl = "AutolinkHeader-module--cds--layout--size-xl--6011f";
export var cdsLayoutSizeXs = "AutolinkHeader-module--cds--layout--size-xs--90206";
export var cdsShowFeedback = "AutolinkHeader-module--cds--show-feedback--2943a";
export var cdsSkeleton = "AutolinkHeader-module--cds--skeleton--7ef5b";
export var cdsVisuallyHidden = "AutolinkHeader-module--cds--visually-hidden--14f5d";
export var header = "AutolinkHeader-module--header--496a4";
export var leftAnchor = "AutolinkHeader-module--left-anchor--cfd56";
export var rightAnchor = "AutolinkHeader-module--right-anchor--fd10b";
export var spacedChildren = "AutolinkHeader-module--spacedChildren--1fe6f";
export var textLeft = "AutolinkHeader-module--textLeft--83794";
export var textRight = "AutolinkHeader-module--textRight--b10a8";