import React from 'react';

// Components
import { CopyButton } from '@carbon/react';
import { Launch } from '@carbon/react/icons';

// Lib
import copy from 'copy-to-clipboard';
import useMetadata from '../../util/hooks/useMetadata';

// Styles
import cn from 'clsx';
import * as css from './Code.module.scss';

export interface SidebarProps {
    src: string;
    path: string;
    children: string;
}

// If there is a src url, but no path name, both icons appear in the sidebar.
// If there is a path name, but no src url, the copy button should be in the PathRow
// so we don't put it in the side bar.
//
// We'll still render the sidebar regardless of the path since we want the text fade.
export default function Sidebar({ src, path, children }) {
    const { interiorTheme } = useMetadata();
    const shouldShowSrcLink = !path && src;
    const shouldShowCopyButton = !path || src;
    return (
        <div className={cn(css.sidebar, interiorTheme === 'dark' && css.dark)}>
            {shouldShowCopyButton && (
                <CopyButton align="left" className={cn(css.button, css.copyButton)} onClick={() => copy(children)} />
            )}
            {shouldShowSrcLink && (
                <a // eslint-disable-line gatsby/use-gatsby-link
                    title="View source"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={css.button}
                    href={src}
                    style={{ zIndex: 200 }}
                    children={<Launch />}
                />
            )}
        </div>
    );
}
