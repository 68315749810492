// extracted by mini-css-extract-plugin
export var accordion = "Accordion-module--accordion--e30e3";
export var cdsAssistiveText = "Accordion-module--cds--assistive-text--2131f";
export var cdsHideFeedback = "Accordion-module--cds--hide-feedback--d4133";
export var cdsLayoutConstraintDensity__defaultCondensed = "Accordion-module--cds--layout-constraint--density__default-condensed--b2619";
export var cdsLayoutConstraintDensity__defaultNormal = "Accordion-module--cds--layout-constraint--density__default-normal--a4928";
export var cdsLayoutConstraintDensity__maxCondensed = "Accordion-module--cds--layout-constraint--density__max-condensed--7e068";
export var cdsLayoutConstraintDensity__maxNormal = "Accordion-module--cds--layout-constraint--density__max-normal--acb00";
export var cdsLayoutConstraintDensity__minCondensed = "Accordion-module--cds--layout-constraint--density__min-condensed--04dfb";
export var cdsLayoutConstraintDensity__minNormal = "Accordion-module--cds--layout-constraint--density__min-normal--9cc98";
export var cdsLayoutConstraintSize__default2xl = "Accordion-module--cds--layout-constraint--size__default-2xl--10920";
export var cdsLayoutConstraintSize__defaultLg = "Accordion-module--cds--layout-constraint--size__default-lg--7e1ba";
export var cdsLayoutConstraintSize__defaultMd = "Accordion-module--cds--layout-constraint--size__default-md--7c921";
export var cdsLayoutConstraintSize__defaultSm = "Accordion-module--cds--layout-constraint--size__default-sm--f4c5d";
export var cdsLayoutConstraintSize__defaultXl = "Accordion-module--cds--layout-constraint--size__default-xl--4ae3c";
export var cdsLayoutConstraintSize__defaultXs = "Accordion-module--cds--layout-constraint--size__default-xs--a2039";
export var cdsLayoutConstraintSize__max2xl = "Accordion-module--cds--layout-constraint--size__max-2xl--fafbd";
export var cdsLayoutConstraintSize__maxLg = "Accordion-module--cds--layout-constraint--size__max-lg--2526a";
export var cdsLayoutConstraintSize__maxMd = "Accordion-module--cds--layout-constraint--size__max-md--47659";
export var cdsLayoutConstraintSize__maxSm = "Accordion-module--cds--layout-constraint--size__max-sm--80fca";
export var cdsLayoutConstraintSize__maxXl = "Accordion-module--cds--layout-constraint--size__max-xl--2b676";
export var cdsLayoutConstraintSize__maxXs = "Accordion-module--cds--layout-constraint--size__max-xs--14b73";
export var cdsLayoutConstraintSize__min2xl = "Accordion-module--cds--layout-constraint--size__min-2xl--54117";
export var cdsLayoutConstraintSize__minLg = "Accordion-module--cds--layout-constraint--size__min-lg--d7559";
export var cdsLayoutConstraintSize__minMd = "Accordion-module--cds--layout-constraint--size__min-md--c523c";
export var cdsLayoutConstraintSize__minSm = "Accordion-module--cds--layout-constraint--size__min-sm--02819";
export var cdsLayoutConstraintSize__minXl = "Accordion-module--cds--layout-constraint--size__min-xl--739c9";
export var cdsLayoutConstraintSize__minXs = "Accordion-module--cds--layout-constraint--size__min-xs--f3d55";
export var cdsLayoutDensityCondensed = "Accordion-module--cds--layout--density-condensed--1b894";
export var cdsLayoutDensityNormal = "Accordion-module--cds--layout--density-normal--f49da";
export var cdsLayoutSize2xl = "Accordion-module--cds--layout--size-2xl--26cba";
export var cdsLayoutSizeLg = "Accordion-module--cds--layout--size-lg--e3249";
export var cdsLayoutSizeMd = "Accordion-module--cds--layout--size-md--bde59";
export var cdsLayoutSizeSm = "Accordion-module--cds--layout--size-sm--b69b6";
export var cdsLayoutSizeXl = "Accordion-module--cds--layout--size-xl--a0c04";
export var cdsLayoutSizeXs = "Accordion-module--cds--layout--size-xs--8247c";
export var cdsShowFeedback = "Accordion-module--cds--show-feedback--a6840";
export var cdsSkeleton = "Accordion-module--cds--skeleton--ccd3e";
export var cdsVisuallyHidden = "Accordion-module--cds--visually-hidden--75c98";