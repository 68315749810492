import React, { type HTMLAttributes } from 'react';
import cx from 'clsx';
import * as css from './Markdown.module.scss';

export type OlProps = HTMLAttributes<HTMLOListElement> & {
    nested?: boolean;
    start?: number | string;
};

export default function Ol({ className, start, ...rest }: OlProps) {
    const classNames = cx(`cds--list--ordered--native`, css.list, className);
    return <ol {...rest} className={classNames} start={start != null ? Number(start) : undefined} />;
}
