// extracted by mini-css-extract-plugin
export var cdsAssistiveText = "Tabs-module--cds--assistive-text--ae63e";
export var cdsHideFeedback = "Tabs-module--cds--hide-feedback--29f41";
export var cdsLayoutConstraintDensity__defaultCondensed = "Tabs-module--cds--layout-constraint--density__default-condensed--9eeba";
export var cdsLayoutConstraintDensity__defaultNormal = "Tabs-module--cds--layout-constraint--density__default-normal--6b238";
export var cdsLayoutConstraintDensity__maxCondensed = "Tabs-module--cds--layout-constraint--density__max-condensed--7acc1";
export var cdsLayoutConstraintDensity__maxNormal = "Tabs-module--cds--layout-constraint--density__max-normal--6a501";
export var cdsLayoutConstraintDensity__minCondensed = "Tabs-module--cds--layout-constraint--density__min-condensed--949df";
export var cdsLayoutConstraintDensity__minNormal = "Tabs-module--cds--layout-constraint--density__min-normal--634f7";
export var cdsLayoutConstraintSize__default2xl = "Tabs-module--cds--layout-constraint--size__default-2xl--42308";
export var cdsLayoutConstraintSize__defaultLg = "Tabs-module--cds--layout-constraint--size__default-lg--3052c";
export var cdsLayoutConstraintSize__defaultMd = "Tabs-module--cds--layout-constraint--size__default-md--afb5b";
export var cdsLayoutConstraintSize__defaultSm = "Tabs-module--cds--layout-constraint--size__default-sm--fac76";
export var cdsLayoutConstraintSize__defaultXl = "Tabs-module--cds--layout-constraint--size__default-xl--b2f94";
export var cdsLayoutConstraintSize__defaultXs = "Tabs-module--cds--layout-constraint--size__default-xs--d473d";
export var cdsLayoutConstraintSize__max2xl = "Tabs-module--cds--layout-constraint--size__max-2xl--25ba3";
export var cdsLayoutConstraintSize__maxLg = "Tabs-module--cds--layout-constraint--size__max-lg--1c7c5";
export var cdsLayoutConstraintSize__maxMd = "Tabs-module--cds--layout-constraint--size__max-md--419c2";
export var cdsLayoutConstraintSize__maxSm = "Tabs-module--cds--layout-constraint--size__max-sm--3ef2c";
export var cdsLayoutConstraintSize__maxXl = "Tabs-module--cds--layout-constraint--size__max-xl--ed16c";
export var cdsLayoutConstraintSize__maxXs = "Tabs-module--cds--layout-constraint--size__max-xs--675bd";
export var cdsLayoutConstraintSize__min2xl = "Tabs-module--cds--layout-constraint--size__min-2xl--cdd5b";
export var cdsLayoutConstraintSize__minLg = "Tabs-module--cds--layout-constraint--size__min-lg--5419a";
export var cdsLayoutConstraintSize__minMd = "Tabs-module--cds--layout-constraint--size__min-md--d56dd";
export var cdsLayoutConstraintSize__minSm = "Tabs-module--cds--layout-constraint--size__min-sm--c174e";
export var cdsLayoutConstraintSize__minXl = "Tabs-module--cds--layout-constraint--size__min-xl--3fbca";
export var cdsLayoutConstraintSize__minXs = "Tabs-module--cds--layout-constraint--size__min-xs--6b8c7";
export var cdsLayoutDensityCondensed = "Tabs-module--cds--layout--density-condensed--e70bf";
export var cdsLayoutDensityNormal = "Tabs-module--cds--layout--density-normal--61bab";
export var cdsLayoutSize2xl = "Tabs-module--cds--layout--size-2xl--64a8f";
export var cdsLayoutSizeLg = "Tabs-module--cds--layout--size-lg--8117e";
export var cdsLayoutSizeMd = "Tabs-module--cds--layout--size-md--f7bda";
export var cdsLayoutSizeSm = "Tabs-module--cds--layout--size-sm--e3833";
export var cdsLayoutSizeXl = "Tabs-module--cds--layout--size-xl--f6b0a";
export var cdsLayoutSizeXs = "Tabs-module--cds--layout--size-xs--1b4a1";
export var cdsShowFeedback = "Tabs-module--cds--show-feedback--e3f19";
export var cdsSkeleton = "Tabs-module--cds--skeleton--a10f4";
export var cdsVisuallyHidden = "Tabs-module--cds--visually-hidden--98452";
export var dropdownWrapper = "Tabs-module--dropdownWrapper--5964d";
export var panel = "Tabs-module--panel--f6746";
export var tab = "Tabs-module--tab--0d944";
export var tabList = "Tabs-module--tab-list--0aab6";