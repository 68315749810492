// Gatsby doesn't include the recommended exceptions to this rule
// https://github.com/evcohen/eslint-plugin-jsx-a11y/blob/master/docs/rules/no-noninteractive-element-to-interactive-role.md#rule-details

import React, { useContext, createContext, useState, useEffect, useRef, useCallback } from 'react';
import { Dropdown } from '@carbon/react';
import { useMedia } from 'use-media';
import { breakpoints } from '@carbon/elements';
import { useId } from '../../util/hooks/useId';

import cn from 'clsx';
import * as css from './Tabs.module.scss';

function elementIsNullOrString(child) {
    return child == null || typeof child.type === 'string';
}

type TabContextType = {
    setActiveTab(index: number): void;
    activeTab: number;
    tabList: Array<HTMLElement | null>;
};
const TabContext = createContext<TabContextType>({
    setActiveTab() {},
    activeTab: 0,
    tabList: [],
});

type SelectProps = {
    children: ReactElement;
    _id: string;
};
function Select({ children, _id }: SelectProps) {
    const { setActiveTab } = useContext(TabContext);
    const items = React.Children.map(children, (child, index) => ({
        label: child.props.label,
        index,
    }));
    return (
        <div className={css.dropdownWrapper}>
            <Dropdown
                size="lg"
                onChange={({ selectedItem }) => (selectedItem ? setActiveTab(selectedItem.index) : null)}
                initialSelectedItem={items[0]}
                label="tab selection"
                items={items}
                id={_id}
            />
        </div>
    );
}

type TabListProps = {
    children: ReactElement;
    className?: string;
    _id: string;
};
function TabList({ children, className, _id }: TabListProps) {
    const { activeTab } = useContext(TabContext);
    return (
        <ul
            className={cn(className, css.tabList)}
            role="tablist"
            children={React.Children.map(children, (child, index) => {
                if (elementIsNullOrString(child)) return child;
                return React.cloneElement(child, {
                    _id: `${_id}__${index}`,
                    active: activeTab === index,
                    index,
                    tab: true,
                });
            })}
        />
    );
}

export function Tab({ _id, label, active, index, tab, children }) {
    const { setActiveTab, tabList } = useContext(TabContext);
    const buttonRef = useCallback<(ref: null | HTMLElement) => void>(ref => tabList.push(ref), [tabList]);

    const onKeyDown = e => {
        let nextButton;
        switch (e.which) {
            case 35: // end
                e.preventDefault();
                tabList[tabList.length - 1]?.focus();
                break;
            case 36: // home
                e.preventDefault();
                tabList[0]?.focus();
                break;
            case 37: // left
                e.preventDefault();
                nextButton = tabList[index - 1] || tabList[tabList.length - 1];
                nextButton.focus();
                break;
            case 39: // right
                e.preventDefault();
                nextButton = tabList[index + 1] || tabList[0];
                nextButton.focus();
                break;
            default:
        }
    };

    if (tab) {
        return (
            <li role="presentation">
                <button
                    className={css.tab}
                    ref={buttonRef}
                    onKeyDown={onKeyDown}
                    onClick={() => setActiveTab(index)}
                    onFocus={() => setActiveTab(index)}
                    type="button"
                    role="tab"
                    id={`${_id}--tab`}
                    tabIndex={!active ? -1 : 0}
                    aria-selected={active || undefined}
                >
                    {label}
                </button>
            </li>
        );
    }

    return (
        <section
            className={css.panel}
            hidden={!active}
            role="tabpanel"
            id={`${_id}--panel`}
            aria-labelledby={`${_id}--tab`}
        >
            {children}
        </section>
    );
}
export function Tabs(props) {
    const tabList = useRef([]);
    const [activeTab, setActiveTab] = useState(0);
    const isMobile = useMedia({ maxWidth: breakpoints.md.width });
    const id = useId('tabs');

    // clear tablist when unmounted (switching between Select and TabList)
    useEffect(() => {
        return () => {
            tabList.current = [];
        };
    });

    return (
        <TabContext.Provider value={{ setActiveTab, activeTab, tabList: tabList.current }}>
            {isMobile ? <Select _id={id}>{props.children}</Select> : <TabList _id={id}>{props.children}</TabList>}
            {React.Children.map(props.children, (child, index) => {
                if (elementIsNullOrString(child)) return child;
                return React.cloneElement(child, {
                    _id: `${id}__${index}`,
                    active: activeTab === index,
                    index,
                });
            })}
        </TabContext.Provider>
    );
}
