/**
 * @typedef {Object} Lang
 * @property {String} key - ISO-639 language code (lowerase two-letter).
 * @property {String} label - Language name in the respective language.
 * @property {Boolean} isRTL - Whether the language uses a right-to-left text direction.
 * @property {String} country - ISO-3166 Country code if applicable (uppercase two-letter).
 */

/** @type {Lang} */
export const en = {
    key: 'en',
    label: 'English',
    isRTL: false,
    country: undefined,
};

/** @type {Lang} */
export const es = {
    key: 'es',
    label: 'Español',
    isRTL: false,
    country: undefined,
};

/** @type {Lang} */
export const ru = {
    key: 'ru',
    label: 'Русский',
    isRTL: false,
    country: 'RU',
};

/** @type {Lang} */
export const fa = {
    key: 'fa',
    label: 'فارسی',
    isRTL: true,
    country: undefined,
};

export const defaultLangKey = 'en';
export const langs = [en, es, ru, fa];
