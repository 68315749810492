import React, { type ComponentProps, type HTMLAttributes } from 'react';
import { FlexGrid, Column as CarbonColumn } from '@carbon/react';

import cn from 'clsx';
import * as css from './Grid.module.scss';

export type GridProps = ComponentProps<typeof FlexGrid>;
export function Grid({ children, className, ...rest }: GridProps) {
    return <FlexGrid className={className} {...rest} children={children} />;
}

export type RowProps = HTMLAttributes<HTMLDivElement>;
export function Row({ children, className, ...rest }: RowProps) {
    return <div className={cn(`cds--row`, className)} {...rest} children={children} />;
}

export type ColumnProps = {
    colSm?: number;
    colMd?: number;
    colLg?: number;
    colXl?: number;
    colMax?: number;

    offsetLg?: number;
    offsetMd?: number;
    offsetSm?: number;
    offsetXl?: number;
    offsetMax?: number;

    noGutterSm?: boolean;
    noGutterMd?: boolean;
    noGutterLg?: boolean;
    noGutterXl?: boolean;
    noGutterMax?: boolean;

    noGutterSmLeft?: boolean;
    noGutterSmRight?: boolean;
    noGutterMdLeft?: boolean;
    noGutterMdRight?: boolean;
    noGutterLgLeft?: boolean;
    noGutterLgRight?: boolean;
    noGutterXlLeft?: boolean;
    noGutterXlRight?: boolean;
    noGutterMaxLeft?: boolean;
    noGutterMaxRight?: boolean;

    gutterLg?: boolean;

    children: ReactNode;
    className?: string;
};
export function Column(props: ColumnProps) {
    const {
        children,
        colSm,
        colMd,
        colLg = 12,
        colXl,
        colMax,
        offsetSm,
        offsetMd,
        offsetLg,
        offsetXl,
        offsetMax,
        noGutterSm,
        noGutterMd,
        noGutterLg,
        noGutterXl,
        noGutterMax,
        noGutterSmLeft,
        noGutterSmRight,
        noGutterMdLeft,
        noGutterMdRight,
        noGutterLgLeft,
        noGutterLgRight,
        noGutterXlLeft,
        noGutterXlRight,
        noGutterMaxLeft,
        noGutterMaxRight,
        gutterLg,
        className,
        ...rest
    } = props;
    const colClasses = cn(
        css.column,
        noGutterSm && `cds--no-gutter-sm`,
        noGutterMd && `cds--no-gutter-md`,
        noGutterLg && `cds--no-gutter-lg`,
        noGutterXl && `cds--no-gutter-xlg`,
        noGutterMax && `cds--no-gutter-max`,
        noGutterSmLeft && `cds--no-gutter-sm--left`,
        noGutterSmRight && `cds--no-gutter-sm--right`,
        noGutterMdLeft && `cds--no-gutter-md--left`,
        noGutterMdRight && `cds--no-gutter-md--right`,
        noGutterLgLeft && `cds--no-gutter-lg--left`,
        noGutterLgRight && `cds--no-gutter-lg--right`,
        noGutterXlLeft && `cds--no-gutter-xlg--left`,
        noGutterXlRight && `cds--no-gutter-xlg--right`,
        noGutterMaxLeft && `cds--no-gutter-max--left`,
        noGutterMaxRight && `cds--no-gutter-max--right`,
        offsetSm && `cds--offset-sm-${offsetSm}`,
        offsetMd && `cds--offset-md-${offsetMd}`,
        offsetLg && `cds--offset-lg-${offsetLg}`,
        offsetXl && `cds--offset-xlg-${offsetXl}`,
        offsetMax && `cds--offset-max-${offsetMax}`,
        gutterLg && `cds--gutter-lg`,
        className,
    );

    return (
        <CarbonColumn
            sm={colSm}
            md={colMd}
            lg={colLg}
            xlg={colXl}
            max={colMax}
            className={colClasses}
            {...rest}
            children={children}
        />
    );
}
