import React, { Fragment, Component } from 'react';
import { Link } from 'gatsby';

import { Launch, Download, ArrowRight, Error, Email } from '@carbon/react/icons';
import cx from 'clsx';

export type ImageCardProps = {
    children?: ReactNode;

    href?: string;

    title?: string;
    titleColor?: 'white' | 'dark';

    subTitle?: string;
    subTitleColor: 'white' | 'dark';

    actionIcon: 'launch' | 'arrowRight' | 'download' | 'email' | 'disabled';
    iconColor: 'white' | 'dark';

    aspectRatio?: '1:1' | '16:9' | '4:3' | '2:1' | '1:2';

    disabled?: boolean;
    className?: string;
    hoverColor?: 'white' | 'dark';

    linkProps?: Dict;
};

export default class ImageCard extends Component<ImageCardProps> {
    static defaultProps = {
        aspectRatio: '1:1',
        titleColor: 'white',
        subTitleColor: 'white',
        iconColor: 'white',
        hoverColor: 'white',
    };

    render() {
        const {
            children,
            href,
            subTitle,
            title,
            titleColor,
            subTitleColor,
            iconColor,
            hoverColor,
            disabled,
            aspectRatio,
            actionIcon,
            className,
            ...rest
        } = this.props;

        let isLink;
        if (href !== undefined) isLink = href.charAt(0) === '/';

        const ImageCardClassNames = cx(
            `cds--image-card`,
            disabled && `cds--image-card--disabled`,
            hoverColor === 'dark' && `cds--image-card--dark`,
            className,
        );

        const aspectRatioClassNames = cx(
            `cds--aspect-ratio`,
            `cds--image-card__spacing`,
            aspectRatio === '2:1' && `cds--aspect-ratio--2x1`,
            aspectRatio === '1:2' && `cds--aspect-ratio--1x2`,
            aspectRatio === '1:1' && `cds--aspect-ratio--1x1`,
            aspectRatio === '16:9' && `cds--aspect-ratio--16x9`,
            aspectRatio === '4:3' && `cds--aspect-ratio--4x3`,
        );

        const carbonTileclassNames = cx(`cds--tile`, href != null && `cds--tile--clickable`);
        const titleClassNames = cx(`cds--image-card__title`, titleColor === 'dark' && `cds--image-card__title--dark`);
        const subTitleClassNames = cx(
            `cds--image-card__subtitle`,
            subTitleColor === 'dark' && `cds--image-card__subtitle--dark`,
        );
        const iconClassNames = cx(
            `cds--image-card__icon--action`,
            iconColor === 'dark' && `cds--image-card__icon--action--dark`,
        );

        const cardContent = (
            <Fragment>
                {subTitle ? <h5 className={subTitleClassNames} children={subTitle} /> : null}
                {title ? <h4 className={titleClassNames} children={title} /> : null}
                <div className={iconClassNames}>
                    {actionIcon === 'launch' && !disabled ? <Launch size={20} aria-label="Open resource" /> : null}
                    {actionIcon === 'arrowRight' && !disabled ? (
                        <ArrowRight size={20} aria-label="Open resource" />
                    ) : null}
                    {actionIcon === 'download' && !disabled ? <Download size={20} aria-label="Download" /> : null}
                    {actionIcon === 'email' && !disabled ? <Email size={20} aria-label="Email" /> : null}
                    {actionIcon === 'disabled' || disabled === true ? <Error size={20} aria-label="disabled" /> : null}
                </div>
                <div className="cds--image-card__img" children={children} />
            </Fragment>
        );

        let cardContainer;
        if (disabled === true || href === undefined) {
            cardContainer = <div className={carbonTileclassNames}>{cardContent}</div>;
        } else if (isLink === true) {
            cardContainer = <Link to={href} className={carbonTileclassNames} children={cardContent} />;
        } else {
            cardContainer = (
                // eslint-disable-next-line gatsby/use-gatsby-link
                <a href={href} className={carbonTileclassNames} children={cardContent} />
            );
        }

        return (
            <div className={ImageCardClassNames} {...rest}>
                <div className={aspectRatioClassNames}>
                    <div className="cds--aspect-ratio--object" children={cardContainer} />
                </div>
            </div>
        );
    }
}
