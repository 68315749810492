import React, { Fragment, useState, useCallback } from 'react';
import { isElement } from 'react-is';
import { FormattedMessage } from 'react-intl';

import { renderToString } from 'lib/react';
import useMetadata from 'src/util/hooks/useMetadata';

// Components
import { Highlight, type Language, type Token } from 'prism-react-renderer';
import { ChevronDown, ChevronUp } from '@carbon/react/icons';
import { Row } from '../Grid';

// Locals
import getTheme from './getTheme';
import PathRow from './PathRow';
import Sidebar from './Sidebar';

import cn from 'clsx';
import * as css from './Code.module.scss';

export type CodeProps = {
    children?: ReactNode;
    src?: string;
    path?: string;

    expanded?: boolean;

    // will contain the language name
    // when supplied into a code-block
    className?: string;
};

type Line = Token[];

function removeTrailingEmptyLine(lines: Line[]): Line[] {
    const head = lines.slice(0, -1);
    const tail = lines[lines.length - 1];

    if (tail[0].empty) return head;
    return [...head, tail];
}
function classNameToLanguage(className: string): Language {
    return className.replace(/language-/, '').replace('mdx', 'jsx');
}

export default function Code(props: CodeProps) {
    const { children, src, path, expanded, className: propsClassName = '' } = props;
    let language: string = '';
    let contentSource: ReactNode;

    /**
     * When using this in MDX-2 as a renderer for "pre"
     * (because "code" catches both inline code and code-fence),
     * we will get a nested `<code className="language-$$$" />` child element.
     */
    if (isElement(children) && children.type === 'code') {
        language = classNameToLanguage(children.props.className || '');
        contentSource = children.props.children;
    } else {
        language = propsClassName.replace(/language-/, '').replace('mdx', 'jsx');
        contentSource = children;
    }

    const contentString = renderToString(contentSource, 'text');
    const [hasMoreThanNineLines, setHasMoreThanNineLines] = useState(false);
    const [shouldShowMore, setShouldShowMore] = useState(false);
    const { interiorTheme } = useMetadata();

    const getLines = useCallback(
        (lines: Line[]): Line[] => {
            const withoutTrailingEmpty = removeTrailingEmptyLine(lines);
            if (withoutTrailingEmpty.length > 9) setTimeout(() => setHasMoreThanNineLines(true), 0);

            return shouldShowMore || expanded ? withoutTrailingEmpty : withoutTrailingEmpty.slice(0, 9);
        },
        [shouldShowMore, expanded],
    );

    return (
        <Row className={cn(css.row)}>
            <PathRow src={src} path={path} code={contentString} />

            <Highlight
                code={contentString}
                language={language as Language}
                theme={getTheme(interiorTheme)}
                children={({ className, style, tokens, getLineProps, getTokenProps }) => {
                    const lines = getLines(tokens);

                    return (
                        <div className={css.container}>
                            <pre
                                className={cn(css.highlight, !path && src && css.sideBarMinHeight, className)}
                                style={style}
                                children={lines.map((line, i) => (
                                    <div
                                        {...getLineProps({ line, key: i })}
                                        children={line.map((token, key) => (
                                            <span {...getTokenProps({ token, key })} />
                                        ))}
                                    />
                                ))}
                            />
                            <Sidebar path={path} src={src} children={contentString} />
                        </div>
                    );
                }}
            />

            {hasMoreThanNineLines && expanded !== true && (
                <button
                    type="button"
                    onClick={() => setShouldShowMore(!shouldShowMore)}
                    className={cn(css.showMoreButton, interiorTheme === 'dark' && css.dark)}
                    children={
                        shouldShowMore ? (
                            <Fragment>
                                <FormattedMessage tagName="span" defaultMessage="Show less" />
                                <ChevronUp />
                            </Fragment>
                        ) : (
                            <Fragment>
                                <FormattedMessage tagName="span" defaultMessage="Show more" />
                                <ChevronDown />
                            </Fragment>
                        )
                    }
                />
            )}
        </Row>
    );
}
