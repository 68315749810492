import React from 'react';
import PropTypes from 'prop-types';
import cx from 'clsx';
import * as css from './Aside.module.scss';

const Aside = ({ children, className, hideRule, ...rest }) => {
    const captionClasses = cx(css.aside, !!hideRule && css.asideNoRule, className);
    return <aside className={captionClasses} {...rest} children={children} />;
};

export default Aside;

Aside.propTypes = {
    children: PropTypes.node,

    /**
     * Specify a custom class
     */
    className: PropTypes.string,

    /**
     * Hide the hanging rule
     */
    hideRule: PropTypes.bool,
};
