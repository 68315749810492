import { graphql, useStaticQuery } from 'gatsby';

type SiteMetadata = {
    title: string;
    description: string;
    keywords: string;
    homepageTheme: 'dark' | 'white' | 'g10';
    interiorTheme: string;
    lang: string;
};

export default function useMetadata(): SiteMetadata {
    const data = useStaticQuery<Queries.UseMetadataHookQuery>(graphql`
        query UseMetadataHook {
            site {
                siteMetadata {
                    title
                    description
                    keywords
                    homepageTheme
                    interiorTheme
                    lang
                }
            }
        }
    `);

    const meta = { ...data.site?.siteMetadata };
    return meta as SiteMetadata;
}
