// extracted by mini-css-extract-plugin
export var cdsAssistiveText = "Title-module--cds--assistive-text--238fc";
export var cdsHideFeedback = "Title-module--cds--hide-feedback--ddcbc";
export var cdsLayoutConstraintDensity__defaultCondensed = "Title-module--cds--layout-constraint--density__default-condensed--16423";
export var cdsLayoutConstraintDensity__defaultNormal = "Title-module--cds--layout-constraint--density__default-normal--2edc0";
export var cdsLayoutConstraintDensity__maxCondensed = "Title-module--cds--layout-constraint--density__max-condensed--1867e";
export var cdsLayoutConstraintDensity__maxNormal = "Title-module--cds--layout-constraint--density__max-normal--ffc37";
export var cdsLayoutConstraintDensity__minCondensed = "Title-module--cds--layout-constraint--density__min-condensed--f5e15";
export var cdsLayoutConstraintDensity__minNormal = "Title-module--cds--layout-constraint--density__min-normal--0d4bc";
export var cdsLayoutConstraintSize__default2xl = "Title-module--cds--layout-constraint--size__default-2xl--d4755";
export var cdsLayoutConstraintSize__defaultLg = "Title-module--cds--layout-constraint--size__default-lg--af5f7";
export var cdsLayoutConstraintSize__defaultMd = "Title-module--cds--layout-constraint--size__default-md--fb761";
export var cdsLayoutConstraintSize__defaultSm = "Title-module--cds--layout-constraint--size__default-sm--423f6";
export var cdsLayoutConstraintSize__defaultXl = "Title-module--cds--layout-constraint--size__default-xl--b24eb";
export var cdsLayoutConstraintSize__defaultXs = "Title-module--cds--layout-constraint--size__default-xs--035dd";
export var cdsLayoutConstraintSize__max2xl = "Title-module--cds--layout-constraint--size__max-2xl--1c5f5";
export var cdsLayoutConstraintSize__maxLg = "Title-module--cds--layout-constraint--size__max-lg--1b985";
export var cdsLayoutConstraintSize__maxMd = "Title-module--cds--layout-constraint--size__max-md--cffda";
export var cdsLayoutConstraintSize__maxSm = "Title-module--cds--layout-constraint--size__max-sm--83830";
export var cdsLayoutConstraintSize__maxXl = "Title-module--cds--layout-constraint--size__max-xl--bd272";
export var cdsLayoutConstraintSize__maxXs = "Title-module--cds--layout-constraint--size__max-xs--e4aea";
export var cdsLayoutConstraintSize__min2xl = "Title-module--cds--layout-constraint--size__min-2xl--ffc9b";
export var cdsLayoutConstraintSize__minLg = "Title-module--cds--layout-constraint--size__min-lg--2de4e";
export var cdsLayoutConstraintSize__minMd = "Title-module--cds--layout-constraint--size__min-md--948cf";
export var cdsLayoutConstraintSize__minSm = "Title-module--cds--layout-constraint--size__min-sm--7d26e";
export var cdsLayoutConstraintSize__minXl = "Title-module--cds--layout-constraint--size__min-xl--561aa";
export var cdsLayoutConstraintSize__minXs = "Title-module--cds--layout-constraint--size__min-xs--55143";
export var cdsLayoutDensityCondensed = "Title-module--cds--layout--density-condensed--8193e";
export var cdsLayoutDensityNormal = "Title-module--cds--layout--density-normal--b86e6";
export var cdsLayoutSize2xl = "Title-module--cds--layout--size-2xl--00b86";
export var cdsLayoutSizeLg = "Title-module--cds--layout--size-lg--89c22";
export var cdsLayoutSizeMd = "Title-module--cds--layout--size-md--35a8e";
export var cdsLayoutSizeSm = "Title-module--cds--layout--size-sm--652f1";
export var cdsLayoutSizeXl = "Title-module--cds--layout--size-xl--2517d";
export var cdsLayoutSizeXs = "Title-module--cds--layout--size-xs--9f36b";
export var cdsShowFeedback = "Title-module--cds--show-feedback--0c9d9";
export var cdsSkeleton = "Title-module--cds--skeleton--95388";
export var cdsVisuallyHidden = "Title-module--cds--visually-hidden--58229";
export var title = "Title-module--title--0e771";