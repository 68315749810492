require('core-js/stable');
require('core-js/es/promise');
require('lib/polyfill');
require('./src/prism');

require('./src/styles/global.index.scss');
require('./src/styles/global.print.scss');

const React = require('react');

const LANGS_CONFIG = require('./src/data/languages/config');
const wrapRoot = require('./src/util/wrap-root-element').default;
const PageWrapper = require('./src/components/PageWrapper').default;
const { scrollIntoView, getScrollOffset } = require('./src/lib/scrollIntoView');

//
// Helpers
//

/**
 * @param {String} languageKey - Two-letter language code from URL
 */
function injectFreshdesk(languageKey) {
    const lang = LANGS_CONFIG[languageKey] || LANGS_CONFIG.en;
    const langeKeyLong = [lang.key, lang.country].filter(Boolean).join('-');

    // eslint-disable-next-line ssr-friendly/no-dom-globals-in-module-scope
    const doc = document;
    const id = 'freshdesk-widget-script';
    function addScripts() {
        const widgetID = 77000002630;
        const s1 = doc.createElement('script');
        s1.setAttribute('id', id);
        s1.type = 'text/javascript';
        // language=JS
        s1.innerHTML = `
window.fwSettings = { widget_id: ${widgetID}, locale: '${langeKeyLong}' };
!function(){
    if (typeof window.FreshworksWidget !== "function"){
        var n = function() { n.q.push(arguments) };
        n.q = [];
        window.FreshworksWidget = n;
    }
}();
window.FreshworksWidget("setLabels", {
    'es': {
        banner: "¡Hola, hablemos sobre minería!",
        launcher: "Obtener ayuda",
        contact_form: {
            title: "Dejar un mensaje",
            submit: "Enviar"
        }
    },
    'ru-RU': {
        banner: "Поговорим о майнинге?",
        launcher: "Поддержка",
        contact_form: {
            title: "Выполните Ваш запрос",
            submit: "Отправить"
        }
    }
});
    `;

        const s2 = doc.createElement('script');
        s2.setAttribute('id', id + '_2');
        s2.type = 'text/javascript';
        s2.src = `https://euc-widget.freshworks.com/widgets/${widgetID}.js`;
        s2.async = true;
        s2.defer = true;

        doc.body.appendChild(s1);
        doc.body.appendChild(s2);
    }

    if (!doc.getElementById(id)) {
        addScripts();
    } else {
        if (window.FreshworksWidget) window.FreshworksWidget = undefined;
        const s1 = doc.getElementById(id);
        const s2 = doc.getElementById(id + '_2');
        const c1 = doc.getElementById('freshworks-container');
        doc.querySelectorAll('iframe').forEach(function (elem) {
            elem.parentNode.removeChild(elem);
        });
        s1?.remove();
        s2?.remove();
        c1?.remove();
        addScripts();
    }
}

//
// Exports
//

exports.onClientEntry = () => {
    const lang = location.pathname.split('/').filter(Boolean)[0];
    injectFreshdesk(lang);

    // allow transitions once the page has fully rendered
    const body = document.querySelector('body');
    window.addEventListener('load', () => {
        body.classList.remove('body--preload');
    });
};

exports.onInitialClientRender = () => {
    requestAnimationFrame(() => scrollIntoView(window.location.hash, -100));
};
exports.shouldUpdateScroll = ({ routerProps: { location } }) => {
    const offset = getScrollOffset(location.hash, -100);
    return offset != null ? [0, offset] : true;
};

exports.wrapRootElement = (...args) => wrapRoot(...args);
exports.wrapPageElement = ({ element, props }) => <PageWrapper {...props} children={element} />;

exports.onRouteUpdate = ({ location, prevLocation }) => {
    if (!prevLocation) return;
    const currentPathname = location.pathname;
    const prevPathname = prevLocation.pathname;

    const currentLang = currentPathname.split('/').filter(Boolean)[0];
    const prevLang = prevPathname.split('/').filter(Boolean)[0];

    if (currentLang !== prevLang) injectFreshdesk(currentLang);
};
