import React from 'react';

import cn from 'clsx';
import * as css from './Caption.module.scss';

export type CaptionProps = {
    children?: ReactNode;
    fullWidth?: boolean;
    className?: string;
};

export default function Caption({ children, className, fullWidth }: CaptionProps) {
    return <p className={cn(css.caption, !fullWidth && css.captionResponsive, className)} children={children} />;
}
