import React, { type HTMLAttributes } from 'react';

import cn from 'clsx';
import * as css from './Markdown.module.scss';

export type BlockquoteProps = HTMLAttributes<HTMLQuoteElement>;

export default function Blockquote({ className, ...rest }: BlockquoteProps) {
    return <blockquote className={cn(css.blockquote, className)} {...rest} />;
}
