import React, { Fragment, Component } from 'react';

import cx from 'clsx';
import { Link } from 'gatsby';
import { AspectRatio } from '@carbon/react';
import { Row, Column } from '../Grid';
import ResourceCard, { type ResourceCardProps } from '../ResourceCard';

export type FeatureCardProps = {
    children?: ReactNode;
    href?: string;

    title?: string;
    subTitle?: string;

    actionIcon?: ResourceCardProps['actionIcon'];
    color?: ResourceCardProps['color'];

    disabled?: boolean;
    className?: string;

    // Pass through props to resource card
    resourceCardProps: Record<string, unknown>;
};

export default class FeatureCard extends Component<FeatureCardProps> {
    render() {
        const {
            children,
            href,
            subTitle,
            title,
            color,
            disabled,
            actionIcon = 'launch',
            className,
            ...resourceCardProps
        } = this.props;

        const FeatureCardClassNames = cx(`cds--feature-card`, className);
        const cardContent = (
            <Fragment>
                <Row>
                    {/* #906 */}
                    <Column className="cds--feature-card__column" noGutterMdLeft>
                        <AspectRatio className="cds--feature-card__img" ratio="1x1">
                            <div className="cds--aspect-ratio--object cds--feature-background" children={children} />
                        </AspectRatio>
                    </Column>
                </Row>
                <Row className="cds--feature-card__row">
                    <Column
                        colMd={4}
                        colLg={4}
                        offsetLg={8}
                        offsetMd={4}
                        noGutterMdLeft
                        className="cds--feature-card__column"
                    >
                        <ResourceCard
                            title={title}
                            subTitle={subTitle}
                            aspectRatio="2:1"
                            actionIcon={actionIcon}
                            color={color}
                            disabled={disabled}
                            {...resourceCardProps}
                        />
                    </Column>
                </Row>
            </Fragment>
        );

        let cardContainer;
        if (disabled === true) {
            cardContainer = <div>{cardContent}</div>;
        } else if (href?.charAt(0) === '/') {
            cardContainer = <Link className="cds--feature-card__link" to={href} children={cardContent} />;
        } else {
            cardContainer = (
                // eslint-disable-next-line gatsby/use-gatsby-link
                <a className="cds--feature-card__link" href={href} {...resourceCardProps} children={cardContent} />
            );
        }

        return <div className={FeatureCardClassNames}>{cardContainer}</div>;
    }
}
