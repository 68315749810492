import React from 'react';
import MDXProvider from '../components/MDXProvider';
import { NavContextProvider } from './context/NavContext';

export default function wrapRootElement({ element }) {
    return (
        <NavContextProvider>
            <MDXProvider element={element} />
        </NavContextProvider>
    );
}
