/**
 * @see https://github.com/FormidableLabs/prism-react-renderer#faq
 * @see https://prismjs.com/extending.html
 */

import { Prism } from 'prism-react-renderer';

// eslint-disable-next-line ssr-friendly/no-dom-globals-in-module-scope
(typeof global !== 'undefined' ? global : window).Prism = Prism;

// We have to "require" languages because "import" is hoisted to the top,
// where the global "Prism" doesn't exist yet producing an error in the language modules.
require('prismjs/components/prism-bash');
require('prismjs/components/prism-json');
require('prismjs/components/prism-json5');
require('prismjs/components/prism-toml');
require('prismjs/components/prism-csv');
require('prismjs/components/prism-promql');
require('prismjs/components/prism-protobuf');
require('prismjs/components/prism-powershell');

// Custom "language" called "URL" to beautify
// examples of URLs with some dynamic segments

function prism_log() {
    Prism.languages.log = {
        info: /\binfo /i,
        error: /\berr(or)? /i,
        warning: /\bwarn(ing) ?/,
        date: /(jan|feb|mar|apr|may|jun|jul|aug|sep|oct|nov|dec) \d{2} \d{2}:\d{2}:\d{2}(\.\d{3})?/i,
    };
}

function prism_url() {
    Prism.languages.url = {
        path: {
            pattern: /(\/)[\w.]+(?=\/)/,
            lookbehind: true,
        },
        protocol: /^.*?:\/{2}/,
        variable: {
            pattern: /([&?])\w+(?==)/,
            lookbehind: true,
        },
        placeholder: /\[.*?]/,
        value: {
            pattern: /(=)[\w-_]+(?=&|$|#)?/,
            lookbehind: true,
        },
        operator: /[:@?&=]/,
        punctuation: /[/]/,
    };
}

function prism_cmd() {
    // Options
    const optional = {
        pattern: /\[\w+]/,
    };
    const optionKey = { pattern: /( -\w+ )|(--[\w-]+\b)/ };
    const optionValue = {
        pattern: /<\w+>/,
        greedy: true,
    };
    Prism.languages.insertBefore('bash', 'string', { optionKey });

    Prism.languages.cmd = {
        application: {
            pattern: /^\$\s[\w/.-]+(?=\s)/m,
            greedy: true,
            inside: {
                prompt: {
                    pattern: /^\$(?=\s)/m,
                },
            },
        },

        sectionHeader: {
            pattern: /^(Usage|Commands|Options):(?=\s)/m,
        },

        commandEntryHeader: {
            pattern: /^\s{2}[\w-]+(?=\s{2,})/m,
        },

        commandOutput: {
            // Lines prefixed with the `ZWSP` (zero-width-space) symbol
            // will be considered output and not highlighted
            pattern: /^​.*$/m,
            inside: {
                'lang-csv': {
                    pattern: /^​csv​.*$/gi,
                    inside: {
                        hidden: { pattern: /^​csv​/gi, greedy: true },
                        punctuation: { pattern: /,/gi },
                    },
                },
                'lang-proto': {
                    pattern: /^​proto​(.*)$/gi,
                    inside: {
                        hidden: { pattern: /^​proto​/gi, greedy: true },
                        rest: Prism.languages.protobuf,
                    },
                },
            },
        },

        // Generic types
        date: {
            pattern: /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}Z/,
            inside: { punctuation: /[TZ:-]/ },
        },
        string: [
            {
                // https://www.gnu.org/software/bash/manual/html_node/Double-Quotes.html
                pattern: /(^|[^\\](?:\\\\)*)"(?:\\[\s\S]|\$\([^)]+\)|\$(?!\()|`[^`]+`|[^"\\`$])*"/,
                lookbehind: true,
                greedy: true,
            },
            {
                // https://www.gnu.org/software/bash/manual/html_node/Single-Quotes.html
                pattern: /(^|[^$\\])'[^']*'/,
                lookbehind: true,
                greedy: true,
            },
        ],
        number: {
            pattern: /(^|\s)(?:[1-9]\d*|0)(?:[.,]\d+)?\b/,
            lookbehind: true,
        },
        comment: {
            pattern: /(^|[^"{\\$])#.*/,
            lookbehind: true,
        },

        // Variants
        variants: {
            pattern: /(\s)<(.*)\|(.*)>(\s|\n|$)/m,
            lookbehind: true,
            inside: {
                optionKey,
                optionValue: [/\w+/, optionKey, optionValue],
            },
        },

        optional,
        optionKey,
        optionValue,
    };
}

function prism_regexp() {
    Prism.languages.regexp = {
        container: [
            // Start
            /^\//,
            // End + flags
            /\/[gimsxU]*\s*$/,
        ],
        anchor: [
            { pattern: /(^|[^\\])\^/, lookbehind: true },
            { pattern: /(^|[^\\])\$/, lookbehind: true },
        ],
        class: /\\\w/, // \s \w \n …
        escaped: /\\[\\\/\[\]().?^$]/,
        quantifier: [
            // +, *
            /[+*]/,
            // {3}, {4,}
            /\{\d,?}/,
            // {3,4}
            /\{\d+,\d+}/,
        ],
    };
    Prism.languages.insertBefore('regexp', 'anchor', {
        set: {
            pattern: /\[.*?]/,
            inside: {
                class: Prism.languages.regexp.class,
                escaped: Prism.languages.regexp.escaped,
                dash: {
                    pattern: /(\w)-(?=\w)/,
                    lookbehind: true,
                },
                start: /^\[/,
                end: /]$/,
            },
        },
        group: {
            pattern: new RegExp(
                // positive lookahead
                /\(\?=.*?\)/.source +
                    '|' +
                    // negative lookahead
                    /\(\?!.*?\)/.source +
                    '|' +
                    // positive lookbehind
                    /\(\?<=.*?\)/.source +
                    '|' +
                    // negative lookbehind
                    /\(\?<!.*?\)/.source +
                    '|' +
                    // [non]capturing group
                    /\(\?:?.*?\)/.source,
            ),
            inside: {
                // set: Prism.languages.regexp.set,
                class: Prism.languages.regexp.class,
                escaped: Prism.languages.regexp.escaped,
                quantifier: Prism.languages.regexp.quantifier,
            },
        },
    });
}

function prism_cron() {
    Prism.languages.cron = {
        times: {
            //                           month              week
            //         minutes  hours    day      month     day
            pattern: /^([^\s]+)[\s\t]+([^\s]+)[\s\t]+([^\s]+)[\s\t]+([^\s]+)[\s\t]+([^\s]+)/gm,
            greedy: false,
            inside: {
                operator: /[,/-]/,
                star: /[*]/,
                value: /\d+|mon|tue|wed|thu|fri|sat|sun/,
            },
        },
        command: {
            pattern: /.+$/m,
            greedy: false,
            inside: Prism.languages.bash,
        },
    };
}

[prism_log, prism_url, prism_cmd, prism_regexp, prism_cron].forEach(fn => fn());
