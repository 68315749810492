import React, { type ReactElement } from 'react';

import { Row, Column } from '../Grid';
import { Accordion as CarbonAccordion } from '@carbon/react';

import cn from 'clsx';
import * as css from './Accordion.module.scss';

export interface AccordionProps {
    className?: string;
    children: ReactElement<{ style?: CSSProperties }>;
}

export default function Accordion({ className, children, ...rest }) {
    return (
        <Row>
            <Column noGutterSm colLg={8}>
                <CarbonAccordion {...rest} className={cn(css.accordion, className)} children={children} />
            </Column>
        </Row>
    );
}
