// extracted by mini-css-extract-plugin
export var cdsAssistiveText = "Link-module--cds--assistive-text--af8b5";
export var cdsHideFeedback = "Link-module--cds--hide-feedback--c7acc";
export var cdsLayoutConstraintDensity__defaultCondensed = "Link-module--cds--layout-constraint--density__default-condensed--4a65c";
export var cdsLayoutConstraintDensity__defaultNormal = "Link-module--cds--layout-constraint--density__default-normal--44ef0";
export var cdsLayoutConstraintDensity__maxCondensed = "Link-module--cds--layout-constraint--density__max-condensed--8ea14";
export var cdsLayoutConstraintDensity__maxNormal = "Link-module--cds--layout-constraint--density__max-normal--2bcf0";
export var cdsLayoutConstraintDensity__minCondensed = "Link-module--cds--layout-constraint--density__min-condensed--2ee3e";
export var cdsLayoutConstraintDensity__minNormal = "Link-module--cds--layout-constraint--density__min-normal--b2195";
export var cdsLayoutConstraintSize__default2xl = "Link-module--cds--layout-constraint--size__default-2xl--2c356";
export var cdsLayoutConstraintSize__defaultLg = "Link-module--cds--layout-constraint--size__default-lg--a0b8d";
export var cdsLayoutConstraintSize__defaultMd = "Link-module--cds--layout-constraint--size__default-md--02fc0";
export var cdsLayoutConstraintSize__defaultSm = "Link-module--cds--layout-constraint--size__default-sm--61c14";
export var cdsLayoutConstraintSize__defaultXl = "Link-module--cds--layout-constraint--size__default-xl--d57e4";
export var cdsLayoutConstraintSize__defaultXs = "Link-module--cds--layout-constraint--size__default-xs--3c448";
export var cdsLayoutConstraintSize__max2xl = "Link-module--cds--layout-constraint--size__max-2xl--78bca";
export var cdsLayoutConstraintSize__maxLg = "Link-module--cds--layout-constraint--size__max-lg--d5451";
export var cdsLayoutConstraintSize__maxMd = "Link-module--cds--layout-constraint--size__max-md--eb4a1";
export var cdsLayoutConstraintSize__maxSm = "Link-module--cds--layout-constraint--size__max-sm--3bf46";
export var cdsLayoutConstraintSize__maxXl = "Link-module--cds--layout-constraint--size__max-xl--6002c";
export var cdsLayoutConstraintSize__maxXs = "Link-module--cds--layout-constraint--size__max-xs--21f3c";
export var cdsLayoutConstraintSize__min2xl = "Link-module--cds--layout-constraint--size__min-2xl--2d7e4";
export var cdsLayoutConstraintSize__minLg = "Link-module--cds--layout-constraint--size__min-lg--55276";
export var cdsLayoutConstraintSize__minMd = "Link-module--cds--layout-constraint--size__min-md--83786";
export var cdsLayoutConstraintSize__minSm = "Link-module--cds--layout-constraint--size__min-sm--1a33f";
export var cdsLayoutConstraintSize__minXl = "Link-module--cds--layout-constraint--size__min-xl--fb385";
export var cdsLayoutConstraintSize__minXs = "Link-module--cds--layout-constraint--size__min-xs--45632";
export var cdsLayoutDensityCondensed = "Link-module--cds--layout--density-condensed--12711";
export var cdsLayoutDensityNormal = "Link-module--cds--layout--density-normal--e1162";
export var cdsLayoutSize2xl = "Link-module--cds--layout--size-2xl--fe898";
export var cdsLayoutSizeLg = "Link-module--cds--layout--size-lg--b1cbb";
export var cdsLayoutSizeMd = "Link-module--cds--layout--size-md--ff662";
export var cdsLayoutSizeSm = "Link-module--cds--layout--size-sm--e50ef";
export var cdsLayoutSizeXl = "Link-module--cds--layout--size-xl--91f66";
export var cdsLayoutSizeXs = "Link-module--cds--layout--size-xs--554ec";
export var cdsShowFeedback = "Link-module--cds--show-feedback--97394";
export var cdsSkeleton = "Link-module--cds--skeleton--9283a";
export var cdsVisuallyHidden = "Link-module--cds--visually-hidden--abee9";
export var link = "Link-module--link--3fd13";