import React, { Component, createRef } from 'react';
import { navigate } from '@reach/router';
import copy2clipboard from 'copy-to-clipboard';
import { renderToString } from 'lib/react';

import { stringToLinkableID } from 'lib/url';
import { scrollIntoView } from 'lib/scrollIntoView';

// Components
import { AccordionItem as CarbonAccordionItem } from '@carbon/react';

export type AccordionItemProps = {
    title: ReactNode;
    children: ReactNode;

    id?: string;
    open?: boolean;
    disabled?: boolean;
};
type State = {
    isOpen: boolean;
    id: string;
};

export default class AccordionItem extends Component<AccordionItemProps, State> {
    readonly state: State = { isOpen: false, id: '' };

    // A fugly hack, but it ensures a descending order of "z-index"
    // to prevent consequent items being un-selectable and un-clickable…
    #refChildren = (ref: HTMLDivElement | null) => {
        if (!ref) return;

        const li = ref.closest<HTMLLIElement>('li.cds--accordion__item');
        const ul = ref.closest<HTMLUListElement>('ul.cds--accordion');

        if (!li || !ul) return;
        const index: number = Array.from(ul.children).indexOf(li);
        li.style.position = 'relative';
        li.style.zIndex = String(10 + index);
    };

    constructor(props: AccordionItemProps) {
        super(props);
        this.#updateState(props);
    }
    compondidMount = () => this.#scrollIntoView();
    componentDidUpdate(prevProps: AccordionItemProps, prevState: State) {
        if (prevProps.title !== this.props.title || prevProps.id !== this.props.id) this.#updateState(this.props);
        if (prevState.id !== this.state.id) this.#scrollIntoView();
    }

    #getClassName = (): string => `data-id-${this.state.id}`;
    #getSelector = (): string => `.${this.#getClassName()}`;
    #getHref = (): string => {
        const url = new URL(window.location.href);
        url.hash = `#${this.state.id}`;
        return url.href;
    };

    #timeoutUpdateState: number = NaN;
    #updateState = (props: AccordionItemProps) => {
        if (props.id) return props.id;

        window.clearTimeout(this.#timeoutUpdateState);
        this.#timeoutUpdateState = window.setTimeout(() => {
            const str: string = renderToString(props.title, 'text');
            const id: string = stringToLinkableID(str);

            const isAnchored: boolean = window.location.hash === `#${id}`;
            const isOpen: boolean = isAnchored || !!props.open;

            this.setState({ id, isOpen });
        }, 10);
    };

    /**
     * Since the `AccordionItem` component doesn't seem to give a fuck
     * about the "open" prop, we need to simulate a click on the title.
     *
     * This however is also tied to a copying of the URL into clipboard,
     * which doesn't work when the click event wasn't executed by user.
     *
     * We will hack around this by inhibition of the copy code.
     */
    #refTitle = createRef<HTMLDivElement>();
    #inhibitCopy2clipboard: boolean = false;
    #timeoutScrollIntoView: number = NaN;
    #scrollIntoView = () => {
        const { id } = this.state;
        if (window.location.hash !== `#${id}`) return;

        window.clearTimeout(this.#timeoutScrollIntoView);
        scrollIntoView(this.#getSelector(), -100);
        this.#inhibitCopy2clipboard = true;
        this.#handleHeadingClick(true);
        this.#timeoutScrollIntoView = window.setTimeout(() => {
            this.#inhibitCopy2clipboard = true;
        }, 100);
    };
    #handleHeadingClick = (open?: unknown | boolean) => {
        const isOpen = typeof open === 'boolean' ? open : !this.state.isOpen;
        if (this.state.isOpen === isOpen) return;
        const href = this.#getHref();

        this.setState({ isOpen }, () => {
            if (!this.#inhibitCopy2clipboard) copy2clipboard(href);
            if (isOpen) navigate(href, { replace: true });
        });
    };

    render() {
        const { title, children, disabled } = this.props;
        const { isOpen } = this.state;

        return (
            <CarbonAccordionItem
                title={<div children={title} ref={this.#refTitle} />}
                children={<div children={children} ref={this.#refChildren} />}
                open={isOpen}
                disabled={disabled}
                onHeadingClick={this.#handleHeadingClick}
                className={this.#getClassName()}
            />
        );
    }
}
