export function getScrollOffset(target: string | HTMLElement, yOffset: number): undefined | number {
    try {
        const element = typeof target === 'string' ? document.querySelector(target) : target;
        if (!element) return;

        return element.getBoundingClientRect().top + window.scrollY + yOffset;
    } catch (err) {
        console.groupCollapsed('Failed to get target element offset');
        console.log('target: ', target);
        console.log('error: ', err);
        console.groupEnd();
    }
}

export function scrollIntoView(target: string | HTMLElement, yOffset: number): void {
    try {
        const y = getScrollOffset(target, yOffset);
        window.scrollTo({ top: y, behavior: 'smooth' });
    } catch (err) {
        console.groupCollapsed('Failed to scroll to a target element');
        console.log('target: ', target);
        console.log('error: ', err);
        console.groupEnd();
    }
}
