import { graphql, useStaticQuery } from 'gatsby';

export default function usePathprefix(): string {
    return (
        useStaticQuery<Queries.UsePathPrefixHookQuery>(graphql`
            query UsePathPrefixHook {
                site {
                    pathPrefix
                }
            }
        `).site?.pathPrefix ?? ''
    );
}
