import React, { type HTMLAttributes } from 'react';

import cn from 'clsx';
import * as css from './Markdown.module.scss';

export type SupProps = HTMLAttributes<HTMLElement>;

export default function Sup({ className, ...rest }: SupProps) {
    return <sup {...rest} className={cn(css.sup, className)} />;
}
