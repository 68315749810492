import React, { Component, Children } from 'react';

import cx from 'clsx';
import * as css from './AnchorLinks.module.scss';

export type AnchorLinksProps = {
    children: NonNullable<ReactNode>;
    small?: boolean;
    className?: string;
};

export default class AnchorLinks extends Component<AnchorLinksProps> {
    render() {
        const { children, small, className } = this.props;
        const isColumn = React.Children.count(children) > 6;
        const classNames = cx(css.list, small && css.listSmall, isColumn && css.multipleColumns, className);

        return (
            <ul
                className={classNames}
                children={Children.map(children, (link, i) => (
                    <li key={i}>{link}</li>
                ))}
            />
        );
    }
}
