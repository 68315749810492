// extracted by mini-css-extract-plugin
export var cdsAssistiveText = "AnchorLinks-module--cds--assistive-text--dfe81";
export var cdsHideFeedback = "AnchorLinks-module--cds--hide-feedback--708bc";
export var cdsLayoutConstraintDensity__defaultCondensed = "AnchorLinks-module--cds--layout-constraint--density__default-condensed--b8443";
export var cdsLayoutConstraintDensity__defaultNormal = "AnchorLinks-module--cds--layout-constraint--density__default-normal--47689";
export var cdsLayoutConstraintDensity__maxCondensed = "AnchorLinks-module--cds--layout-constraint--density__max-condensed--127e7";
export var cdsLayoutConstraintDensity__maxNormal = "AnchorLinks-module--cds--layout-constraint--density__max-normal--1d49c";
export var cdsLayoutConstraintDensity__minCondensed = "AnchorLinks-module--cds--layout-constraint--density__min-condensed--e0d68";
export var cdsLayoutConstraintDensity__minNormal = "AnchorLinks-module--cds--layout-constraint--density__min-normal--25080";
export var cdsLayoutConstraintSize__default2xl = "AnchorLinks-module--cds--layout-constraint--size__default-2xl--79216";
export var cdsLayoutConstraintSize__defaultLg = "AnchorLinks-module--cds--layout-constraint--size__default-lg--8d2c9";
export var cdsLayoutConstraintSize__defaultMd = "AnchorLinks-module--cds--layout-constraint--size__default-md--c02d2";
export var cdsLayoutConstraintSize__defaultSm = "AnchorLinks-module--cds--layout-constraint--size__default-sm--83c2c";
export var cdsLayoutConstraintSize__defaultXl = "AnchorLinks-module--cds--layout-constraint--size__default-xl--81535";
export var cdsLayoutConstraintSize__defaultXs = "AnchorLinks-module--cds--layout-constraint--size__default-xs--71956";
export var cdsLayoutConstraintSize__max2xl = "AnchorLinks-module--cds--layout-constraint--size__max-2xl--abb4f";
export var cdsLayoutConstraintSize__maxLg = "AnchorLinks-module--cds--layout-constraint--size__max-lg--82c06";
export var cdsLayoutConstraintSize__maxMd = "AnchorLinks-module--cds--layout-constraint--size__max-md--9a37c";
export var cdsLayoutConstraintSize__maxSm = "AnchorLinks-module--cds--layout-constraint--size__max-sm--5a9a9";
export var cdsLayoutConstraintSize__maxXl = "AnchorLinks-module--cds--layout-constraint--size__max-xl--c5728";
export var cdsLayoutConstraintSize__maxXs = "AnchorLinks-module--cds--layout-constraint--size__max-xs--9bcbb";
export var cdsLayoutConstraintSize__min2xl = "AnchorLinks-module--cds--layout-constraint--size__min-2xl--ea0b5";
export var cdsLayoutConstraintSize__minLg = "AnchorLinks-module--cds--layout-constraint--size__min-lg--4f63a";
export var cdsLayoutConstraintSize__minMd = "AnchorLinks-module--cds--layout-constraint--size__min-md--0e513";
export var cdsLayoutConstraintSize__minSm = "AnchorLinks-module--cds--layout-constraint--size__min-sm--d1067";
export var cdsLayoutConstraintSize__minXl = "AnchorLinks-module--cds--layout-constraint--size__min-xl--a0611";
export var cdsLayoutConstraintSize__minXs = "AnchorLinks-module--cds--layout-constraint--size__min-xs--1a3cc";
export var cdsLayoutDensityCondensed = "AnchorLinks-module--cds--layout--density-condensed--a5064";
export var cdsLayoutDensityNormal = "AnchorLinks-module--cds--layout--density-normal--038ba";
export var cdsLayoutSize2xl = "AnchorLinks-module--cds--layout--size-2xl--12ad3";
export var cdsLayoutSizeLg = "AnchorLinks-module--cds--layout--size-lg--ff594";
export var cdsLayoutSizeMd = "AnchorLinks-module--cds--layout--size-md--31987";
export var cdsLayoutSizeSm = "AnchorLinks-module--cds--layout--size-sm--81636";
export var cdsLayoutSizeXl = "AnchorLinks-module--cds--layout--size-xl--7d93f";
export var cdsLayoutSizeXs = "AnchorLinks-module--cds--layout--size-xs--33ee1";
export var cdsShowFeedback = "AnchorLinks-module--cds--show-feedback--5adc8";
export var cdsSkeleton = "AnchorLinks-module--cds--skeleton--51403";
export var cdsVisuallyHidden = "AnchorLinks-module--cds--visually-hidden--a394d";
export var link = "AnchorLinks-module--link--01f15";
export var list = "AnchorLinks-module--list--0bf21";
export var listSmall = "AnchorLinks-module--list--small--7aee1";
export var multipleColumns = "AnchorLinks-module--multiple-columns--04081";