import React, { useReducer, useState, createContext, useContext } from 'react';

type NavKey = keyof Pick<NavContextShape, 'leftNavIsOpen' | 'searchIsOpen' | 'switcherIsOpen' | 'isManagingFocus'>;
type ActionType = 'open' | 'close';
export type NavContextAction = { type?: ActionType; nav: NavKey };

export type NavContextPathInfo = {
    lang: string; // Example: "en",
    prod: string; // Example:     "braiins-pool"
    slug: string; // Example:     "braiins-pool/about",
    path: string; // Example: "/en/braiins-pool/about",
};
export type NavContextShape = {
    leftNavIsOpen: boolean;
    searchIsOpen: boolean;
    switcherIsOpen: boolean;
    toggleNavState(nav: NavContextAction['nav'], type?: NavContextAction['type']): void;

    isManagingFocus: boolean;
    setIsManagingFocus(s: boolean): void;

    leftNavScrollOffset: number;
    leftNavScrollTop: number;
    setLeftNavScrollTop(n: number): void;

    getPathInfo(): NavContextPathInfo;
};

const noop = () => {};
const getInitialNavState = (): NavContextShape => ({
    leftNavIsOpen: false,
    searchIsOpen: false,
    switcherIsOpen: false,
    toggleNavState: noop,

    leftNavScrollOffset: 0,
    leftNavScrollTop: 0,
    setLeftNavScrollTop: noop,

    isManagingFocus: false,
    setIsManagingFocus: noop,

    getPathInfo: () => ({ lang: '', prod: '', slug: '', path: '' }),
});

export const NavContext = createContext<NavContextShape>(getInitialNavState());

function reducer(state: NavContextShape, action: NavContextAction) {
    switch (action.type) {
        case 'open':
            return { ...state, [action.nav]: true };
        case 'close':
            return { ...state, [action.nav]: false };
        default:
            return { ...state, [action.nav]: !state[action.nav] };
    }
}
export function useNavContext(): NavContextShape {
    return useContext(NavContext);
}

export function NavContextProvider({ children }) {
    const [{ leftNavIsOpen, searchIsOpen, switcherIsOpen, leftNavScrollOffset }, dispatch] = useReducer(
        reducer,
        getInitialNavState(),
    );

    const [leftNavScrollTop, setLeftNavScrollTop] = useState(0);
    const toggleNavState = (nav, type) => dispatch({ nav, type });
    const [isManagingFocus, setIsManagingFocus] = useState(false);

    const contextValue = {
        leftNavIsOpen,
        searchIsOpen,
        switcherIsOpen,
        toggleNavState,

        leftNavScrollOffset,
        leftNavScrollTop,
        setLeftNavScrollTop,

        isManagingFocus,
        setIsManagingFocus,

        getPathInfo(): NavContextPathInfo {
            const path = window.location.pathname;
            const fragments = path.split('/').filter(Boolean);

            // Language fragment is a given on every page
            const [lang, ...rest] = fragments;
            const res: NavContextPathInfo = { path, lang, prod: '', slug: rest.join('/') };

            /**
             * …but the rest of the path has two cases:
             *  - product pages: /en/braiins-pool/about
             *  - non-product pages: /en/terms-and-policies
             *
             * Given this contenxt, we need to determine the case based on the number of fragments
             * where product pages always have at least three fragments (lang, prod, page).
             */
            if (rest.length > 1) res.prod = rest[0];

            return res;
        },
    } satisfies NavContextShape;
    return <NavContext.Provider value={contextValue} children={children} />;
}
