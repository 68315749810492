import React, { Fragment, createElement, useCallback, type HTMLAttributes, type ComponentType } from 'react';
import copy2clipboard from 'copy-to-clipboard';
import useMedia from 'use-media';

import { renderToString } from 'lib/react';
import { stringToLinkableID } from 'lib/url';

// Components
import { breakpoints } from '@carbon/elements';
import { Link } from '@carbon/react/icons';

// Styles
import cn from 'clsx';
import * as css from './AutolinkHeader.module.scss';

const Anchor = ({ id, string, position }) => {
    const classes = cn(css.anchor, position === 'left' && css.leftAnchor, position === 'right' && css.rightAnchor);

    const hash = `#${id}`;
    const label = `${string} permalink`;
    const handleCopy = useCallback(() => {
        const url = new URL(window.location.href);
        url.hash = hash;

        copy2clipboard(url.href);
    }, [hash]);

    // eslint-disable-next-line gatsby/use-gatsby-link
    return <a className={classes} href={hash} aria-label={label} children={<Link size={20} />} onClick={handleCopy} />;
};

export type AutolinkHeaderProps = Omit<HTMLAttributes<HTMLElement>, 'is'> & {
    is?: ComponentType<{ className?: string; id?: string }> | keyof JSX.IntrinsicElements;
    children?: ReactNode;
};

export default function AutolinkHeader({ is = 'h2', className, children, ...props }: AutolinkHeaderProps) {
    const isMobile = useMedia({ maxWidth: breakpoints.md.width });

    const string: string = renderToString(children, 'text');
    const id: string = stringToLinkableID(string);
    const anchorPosition = () => (isMobile ? 'right' : 'left');

    return createElement(
        is,
        { className: cn(css.header, className), id, ...props } as Dict,
        <Fragment>
            {children}
            <Anchor id={id} string={string} position={anchorPosition()} />
        </Fragment>,
    );
}
